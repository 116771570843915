@media print {
    .blocRight,
    header,
    #enteteSlideshow,
    .menu-emploi,
    .bouton,
    button,
    .blocArea-contact,
    footer,
    .push {
        display:none !important;
    }
    .nav-inner, .inner {
        max-width:none;
        padding:0;
    }
    .logo-bg {
        margin:0;
        padding:0;
        position:static;
        .logo {
            position:static;
            float:none;
            margin:0;
            padding:0;
        }
    }

    body {
        padding:20px 20px 0;
    }

    h1, .gros_titre {
        font-size:2em !important;
    }
    h2, .moyen_titre {
        font-size:1.5em !important;
    }
    h3, .petit_titre {
        font-size:1.25em !important;
    }

    .template-emploi .module-emploi.module-emploi-recherche .offre {
        padding-left:0;
        padding-right:0;
    }

    .print-only {
        display:block;
    }
    .print-logo {
        padding-bottom:25px;
        line-height:inherit;
    }


    .offre-tools,
    .offre-tools-print {
        display: none;
    }

    .open-popup {
        overflow:auto !important;
        body {
            overflow:auto !important;
        }

        .wrapper,
        .push,
        .mfp-container:before,
        .mfp-bg,
        footer {
            display: none !important;
        }
        .popupOffre {
            position: static;
            max-width: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: auto;
            border: none;
            vertical-align: top;
        }
        .mfp-wrap,
        .mfp-container,
        .mfp-content {
            position: static;
            vertical-align: top;
            height: auto;
            clear:both;
        }
        .mfp-close-btn-in .mfp-close,
        .mfp-bg {
            display: none;
        }
        .mfp-container:before {
            position:absolute;
        }

        .genericFormBloc, .genericPopup {
            line-height:inherit !important;
            .rowBloc,
            .row {
                p, div {
                    line-height:1.3em;
                }
            }
        }
    }
}