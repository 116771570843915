select, input[type="text"], input[type="password"], input[type="email"], textarea {
	@include prefix(box-sizing, border-box);
	padding: 5 px 12px;
	border: solid 1px $color-primary !important;
	color:$color-primary;
	font-size: 1.1em;
	min-height: 35px;
	padding: 0 10px;
	&:focus {
		outline: none;
	}
}

.jcf-select, .jcf-select-text, .jcf-select-text span, .jcf-select-opener {
	min-height: 35px;
	line-height: 35px;
	vertical-align: middle;
}

select{
	-webkit-appearance: none;
	-moz-appearance: none;
	background:#fff url('~src/images/select.png') no-repeat right center;
	cursor:pointer;
	padding: 4px 45px 4px 10px;
}
.jcf-checkbox {
	margin-top:3px !important;
	margin-bottom:3px !important;
}
