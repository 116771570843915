/* Colors */
$colorTexte: #010101;
$colorTexte2: #090430; //Titre manchette
$gris: #10191b; //Gris fonc�

 
/* Fonts */
$fontTexte: 'Lato', sans-serif;
$fontTitre: 'Montserrat', sans-serif;

//Transition
@mixin basictransition($duration : 300ms) {
	-webkit-transition: all $duration ease;
	-moz-transition: all $duration ease;
	-ms-transition: all $duration ease;
	-o-transition: all $duration ease;
	transition: all $duration ease;
}

@mixin prefix($property, $value) {
	-webkit-#{$property}: $value;
	-moz-#{$property}: $value;
	-ms-#{$property}: $value;
	-o-#{$property}: $value;
	#{$property}: $value;
}

