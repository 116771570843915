.template-emploi {
	.offre--null {
		display: none;
	}

    .moyen_titre, h2 {
    }
    .tab-header {
        position:relative;
        .bouton-login {
            position: absolute;
			z-index:100;
            top: 10px;
            right: 0;
            padding-left: 40px;
            padding-right: 40px;
            background-color:#000;
            border-color:#000;
            &:hover {
                background-color:$color-primary;
                border-color:$color-primary;
            }
        }
    }
    .tab-content {

        .bouton, button, input[type="button"] {
            padding-left: 30px;
            padding-right: 30px;
            background:#b2b2b2;
            border-color:#b2b2b2;
            &.bouton-validate {
                background:#00960e;
                border-color:#00960e;
                font-size: 1.3em !important;
                height: 50px;
                width: 200px;
            }
            &:hover {
                background:#000;
                border-color:#000;
            }
            &.boutonSmall {
                padding: 7px;
                line-height: 1em;
                min-height: 27px;
                margin: 0 6px;
            }
        }
        .checkbox-list {
            list-style: none;
            li {
                list-style: none;
                margin: 0 0 8px 0;
                padding-left: 36px;
                input, .jcf-checkbox, .jcf-radio {
                    position: absolute;
                    top: 0;
                    left: 0px;
                }
                &::before {
                    display:none;
                }
            }
        }
    }

    .module-emploi {
        hr {
            margin: 25px 0px;
            border-color: $color-primary;
        }
        .bouton-full {
            width:100%;
            background:$color-primary;
            border-color:$color-primary;
            &:hover {
                background:#000;
                border-color:#000;
            }
        }

        .contain h1 {
            color: #960027;
            margin: 0 0 30px;
        }
        .menu-emploi {
            display: table;
            width: 100%;
            margin: 30px 0 35px;
            @include prefix(box-sizing, border-box);
            > a {
                @include prefix(box-sizing, border-box);
                @include basictransition(500ms);
                width: 15%;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                display: table-cell;
                line-height: 30px;
                padding: 12px 20px 8px;
                color: #ffffff;
                background-color: #960027;
                font-size: 14.5px;
				font-weight:600;
                border-left: 1px solid #ffffff;
                text-align: center;
                span {
                    line-height: 1em;
                    vertical-align: middle;
                    display: inline-block;
                }
                &.active,
                &:hover {
                    background-color: #000000;
                }
                &.disabled {
                    background-color: #000 !important;
                    opacity:0.3;
                    cursor:default;
                }
            }
            &.menu-disabled {
                > a {
                    cursor:default !important;
                    background-color: #960027 !important;
                    &.active {
                        background-color: #000000 !important;
                    }
                }
            }
        }


        form {
            .blocInfo {
                border-top: 0;
                padding: 18px 0;
                position: relative;

                &.noFirst,
                &:first-child {
                    border-top: 2px solid $color-primary;
                }

                h3 {
                    font-size: 12px;
                    font-weight: bold;
                    color: $color-primary;
                    text-transform: uppercase;
                    font-family: $fontTitre;
                    margin-top: 0;
                    padding: 0;
                }

                .row {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.mandatory {
                        position: relative;

                        label {
                        }

                        &:after {
                            content: '';
                            font-size: 15px;
                            font-family: $fontTitre;
                            font-weight: bold;
                            position: absolute;
                            left: 0;
                            top: 5px;
                            color: $color-primary;
                        }
                    }

                    > label, > .label {
                        display: table;
                        width: 100%;
                        box-sizing: border-box;
                        font-weight: 500;
                        font-size: 13px;
                        font-family: $fontTitre;
                        position: relative;

                        > span {
                            display: table-cell;
                            width: 200px;
                            vertical-align: middle;

                            &.titleSwal {
                                cursor: pointer;
                                /*background: url(~src/images/icon-info.png) no-repeat;*/
                                text-indent: -99999px;
                                width: 35px !important;
                                height: 35px;
                            }
                        }
                        > input, textarea, select {
                            display: table-cell;
                            width: 100%;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .infoSupp {
            position:relative;
            text-indent: 30px;
            line-height: 1.5em;
            margin-top:30px;
            font-size:0.9em;
            &:before {
                content: '*';
                position: absolute;
                color: $color-primary;
                font-size: 35px;
                font-weight: bold;
                font-family: $fontTitre;
                top: 3px;
                left: -27px;
            }
        }

        h1 {
            color: #090430;
            margin: 40px 0 40px 50px;
        }

        > .success {
            padding: 0 50px 20px;
            font-weight: bold;
        }

        .titreFormulaire {
            margin: 45px 0 20px 50px;
        }

        .col {
            &.col1 {

                .blocInfo {
                    .row {
                        .inlineBouton {
                            display: table-cell;
                            width: 115px;
                            text-align: right;

                            .bouton {
                                margin-top: -4px;
                            }
                        }
                    }
                }

                .tab-content {
                    h2 {
                        color: $color-primary;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }

                .tableFormation {
                    td {
                        font-weight: bold;
                        text-transform: uppercase;
                        font-size: 13px;
                    }
                }
            }
            &.col2 {
                .blocSidebar {
                    background-color: $color-primary;
                    padding: 30px 50px;
                    color: #ffffff;
                    font-size: 15px;
                    font-weight: bold;

                    h2 {
                        font-size: 15px;
                        font-family: $fontTitre;
                        font-weight: bold;
                        position: relative;
                        padding-left: 40px;
                        vertical-align: top;

                        &:after {
                            content: '*';
                            font-size: 68px;
                            position: absolute;
                            left: 0;
                            top: 10px;
                        }
                    }
                }
            }
        }

        .containerListeOffre {

            h1 {
                color: #090430;
                margin: 40px 0 40px 50px;
            }

            .col {
                &.col1 {
                    padding-left: 50px;
                    box-sizing: border-box;
                    margin-bottom: 30px;

                    .listeOffres {
                        margin-bottom: 30px;

                        .emplois {
                            margin-top: 20px;
                            &:first-child {
                                margin-top: 0;
                            }

                            .titre {
                                text-transform: uppercase;
                                font-size: 15px;
                                font-weight: bold;
                                color: $color-primary;
                            }
                            .date {
                                font-size: 13px;
                                font-weight: bold;
                                color: $colorTexte;
                            }
                        }
                    }
                }

                &.col2 {
                    .blocMenuConventions {
                        background-color: $color-primary;
                        padding: 30px 50px;
                        margin-bottom: 25px;

                        h2 {
                            color: #ffffff;
                            font-weight: bold;
                        }

                        ul {
                            li {
                                a {
                                    font-size: 12px;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    color: #ffffff;
                                    margin: 20px 0;
                                    line-height: 35px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        .containerOffre {
            .wrap {
                .inner {
                    padding-top: 0;
                    text-align: center;

                    h2 {
                        text-align: left;
                        color: $color-primary;
                        font-weight: bold;
                    }

                    .bouton {
                        margin-top: 20px;
                    }
                    .btnPostuler {
                        float: right;
                        margin-top: 0;
                    }

                    .bloc {
                        text-align: left;
                        margin-top: 30px;

                        h3 {
                            padding: 0;
                            margin-bottom: 5px;
                            color: $color-primary;
                        }

                        &:first-of-type {
                            margin-top: 0;
                        }

                        &.autreInfo {
                            hr {
                                border-color: $color-primary;
                                border-width: 2px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }



        .col-3 {
            margin:0 0 30px;
            display:block;
            padding-right:10px;
            .col {
                @include prefix(box-sizing, border-box);
                display:inline-block;
                width:33%;
                vertical-align:top;
                select {
                    width:100%;
                }
            }
        }

        .col-2 {
            margin:0 0 30px;
            display:block;
            .col {
                @include prefix(box-sizing, border-box);
                display:inline-block;
                width:49.5%;
                vertical-align:top;
                select {
                    width:100%;
                }
                &:first-child {
                    padding-right:10px;
                }
                &:nth-child(1) {
                    padding-right:15px;
                }
                &:nth-child(2) {
                    padding-left:15px;
                }
            }
        }

        &.module-emploi-complementaire {
            .tableFormation {
                margin-top:20px;
                border:solid 1px $color-primary;
                tr {
                    border:solid 1px $color-primary;
                    th {
                        font-size:1.4em;
                        font-weight:bold;
                        padding:10px 20px;
                    }
                    td  {
                        padding:8px 20px;
                        vertical-align:middle;
                        > div {
                            position:relative;
                            top:2px;
                        }
                    }
                }
                textarea {
                    margin-top:2px;
                    vertical-align:middle;
                }
            }
        }

        &.module-emploi-profile {
            form .blocInfo .row label input {
                width: 578px;
                max-width:99%;
            }
            form .blocInfo .row label select {
                width: 400px;
            }
            form .blocInfo .row .checkbox-list {
                width: 578px;
                max-width:99%;
                li {
                    display:inline-block;
                    margin-right:20px;
                    line-height: 2em;
                    vertical-align: middle;
                }
            }

            .zoneCv {
                float:left;
                position:relative;
            }

            .error {
                color:#ff0000;
                ul {
                    margin:0;
                    li {
                        list-style: none;
                        margin: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }

        }
        &.module-emploi-categories {
            .col  ul {
                li {
                    padding-left: 36px;
                }
            }
            .col-3 {
                display:table;
                margin-top:40px;
                .col {
                    display:table-cell;
                    border-right: solid 1px $color-primary;
                    padding: 0 20px;
                    h3 {
                        padding:0;
                    }
                    &:nth-child(1) {
                        padding-left:0;
                    }
                    &:nth-child(3) {
                        padding-right:0;
                        border-right: none;
                    }
                }
            }
            .checkcount-area {
                margin:0 10px;
                font-weight:bold;
                color:$color-primary;
            }
        }
        &.module-emploi-login {
            form {
                margin-bottom:25px;
            }
            #login-register,
            #login-form {
                @include prefix(box-sizing, border-box);
                margin-top:2em;
                height:215px;
                display:inline-block;
                border:solid 1px #000;
                vertical-align:top;
                position:relative;
                h2 {
                    position:absolute;
                    top:-3em;
                    left:0;
                }
                .bouton, button {
                    background-color:#000;
                    border-color:#000;
                    &:hover {
                        background-color:$color-primary;
                        border-color:$color-primary;
                    }
                }
            }
            #login-register {
                width:37%;
                background:url('~src/images/big-user-icon.png') no-repeat center 40%;
                a,
				button {
                    position:absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                }
            }
            #login-form {
                width:58%;
                margin-left:4%;
                padding:25px;
                .blocInfo {
                    padding:0;
                }
                button {
                    padding-left:20px;
                    padding-right:20px;
                }
                .password-recovery {
                    float:right;
                    line-height: 35px;
                    vertical-align: middle;
                    color:$color-primary;
                    text-decoration:underline;
                    font-weight:500;
                }
            }
        }
        &.module-emploi-offre {
            .offre-tools {
                float: right;
                margin-top: 6px;
                text-align: right;
            }
        }
        &.module-emploi-recherche {
            label {
                margin-right:30px;
                white-space: nowrap;
            }
            .margin-label {
                margin-bottom:10px;
                font-weight:500;
                font-size:0.97em;
                strong {
                    font-weight:600;
                }
            }
            .label {
                top:2px;
                font-weight:500;
                margin-left:10px;
                position:relative;
            }

            .offre {
                padding: 0 20px 25px;
                margin: 25px 0;
                border-bottom: solid 1px $color-primary;

                &:first-child {
                    border-top: solid 1px $color-primary;
					padding-top:25px;
                }
                .publish {

                }
                .info {
                    margin: 10px 0;
                    .title {
                        color: $color-primary;
                        font-weight: 600;
                        font-size: 1.3em;
                    }
                    .place {
                        font-weight: bold;
                        font-size: 1.1em;
                    }
                    .type {

                    }

                }
                .detail {
                    display:none;
                }

				@include md-() {
					padding-left: 0;
					padding-right: 0;
				}
            }
        }

        .offre,
        .offreUnic {
            button {
                border-color:#b2b2b2;
                background:#b2b2b2;
                padding-left:10px;
                padding-right:10px;
                background-position:-20px center;
                margin: 0 -3px 0 0;

                &:hover {
                    border-color:#000;
                    background-color:#000;
                }
                &.bouton-checked {
                    background:$color-secondary-dark url('~src/images/checkmark.png') no-repeat 10px center;
                    padding-left:40px;
                    border-color:$color-secondary-dark;
                    &:hover {
                        background-image: url('~src/images/checkmark-cross.png');
                    }
                }
            }
        }

        .offreUnic {
            position:relative;
            .offre-tools {
                position:absolute;
                top:0;
                right:0;

            }
        }

    }
    .containerEmplois {
        line-height:1.5em;
    }

	@include md-() {
		h2 {
			clear: both;
		}

		.btnFacultativeTop {
			width: 100%;
			float: none;
			margin-bottom: 20px;
		}
	}

}

.popupOffre {
    padding-bottom: 130px;
    padding-top:50px;
    .offre-tools {
        float: right;
        position: absolute;
        bottom: 30px;
        right: 75px;
        text-align: right;
    }
    .offre-tools-print {
        float: left;
        position: absolute;
        bottom: 36px;
        left: 75px;
        text-align: right;
    }
}
.checkbox-list-columns {
    li {
        width:calc(98% / 2);
        display:inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        vertical-align:top;
        margin: 0 0 2px !important;
        line-height: 2em;
        label {
            line-height:1.1em;
            display:inline-block;
        }
    }
}
.right-boutons {
    float:right;
    margin:15px 0 0;
    .bouton {
        padding:10px 15px !important;
    }
}
.msg-notice {
    width:100%;
    display:block;
    height: inherit;
    text-align: left;
    padding: 20px;
    margin: 0 0 20px;
    background:$color-primary !important;
    white-space: normal;
    &:hover {
        background:#000 !important;
    }
    p {
        margin:5px 0 0;
    }
}



.zoneCv {
    position:relative;
    input {cursor:pointer;}
    span {
        vertical-align:middle;
        display:inline-block !important;
    }
    .fileUpload {
        @include basictransition(0ms);
        position: relative;
        overflow: hidden;
        border:none;
        cursor:pointer;
        vertical-align:middle;
        display:inline-block !important;

        input.upload {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }
    .bouton {
        margin-right:14px;
    }

    #contactPersonneCvLabel {
        margin-left:5px;
        margin-right:20px;
        font-style:italic;
    }
    .icon-label {color:$color-primary;}
    .icon {
        display:inline-block !important;
        background:url('~src/images/doc-cv-no.png') no-repeat center center;
        width:50px;
        height:36px;
        cursor:pointer;
    }
    &.ready {
        .icon {background-image:url('~src/images/doc-cv-yes.png');}
        .icon-label {color:#00960e;}
    }
    &.zoneFile {
        .icon {background-image:url('~src/images/doc-no.png'); }
        &.ready {
            .icon {background-image:url('~src/images/doc-yes.png');}
        }
    }
}
