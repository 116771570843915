@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.reset,
.reset * {
	margin: 0;
	padding: 0;
	border: none;
	background: none;

	:before,
	&:after {
		display: none;
	}
}

.clear {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

@mixin cover($full: true) {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;

	@if ($full == true) {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
	}
}

.cover {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.fullcover {
	@include cover();
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.fa {
	font-family: $font-family-fa !important;
}

// display
.h-inline-block {
	display: inline-block;
}

.h-hidden {
	display: none;
}

// disables scroll on body or html
.h-noscroll {
	overflow-y: hidden;
}

// sets relative position
.h-relative {
	position: relative;
}

// sets the background image to be a centered cover
.h-clear {
	@include clear();
}

// sets the background image to be a centered cover
.h-bgcover {
	@include bgcover();
}

// sets the elements to cover its parent (requires parent to be relative)
.h-absolutecover {
	@include absolutecover();
}

// text variants
.h-text {
	// alignement  (breakpoints modifiers in responsive/helpers)
	&--left {
		text-align: left;
	}

	&--center {
		text-align: center;
	}

	&--right {
		text-align: right;
	}

	// colors
	&--white {
		color: $color-white;
	}

	&--green {
		color: $color-primary;
	}

	&--black {
		color: $color-text-black;
	}

	&--grey {
		color: $color-text-grey;
	}

	&--red {
		color: $color-danger !important;
	}

	// text weight
	&--fw-regular {
		font-weight: $font-weight-regular;
	}

	&--fw-medium {
		font-weight: $font-weight-medium;
	}

	&--fw-semibold {
		font-weight: $font-weight-semibold;
	}

	&--fw-bold {
		font-weight: $font-weight-bold;
	}

	&--fw-900 {
		font-weight: 900;
	}

	// text transform
	&--upper {
		text-transform: uppercase;
	}

	&--cap {
		text-transform: capitalize;
	}
}

// disabled state
.h-disabled {
	pointer-events: none;
	cursor: default;
	user-select: none;
	// opacity: $disabled-opacity;
}

.h-full-height {
	height: 100%;
}

.h-full-width {
	width: 100%;
}

// min height
.h-min-height-5 {
	min-height: 5rem;
}

.h-min-height-10 {
	min-height: 10rem;
}

.h-min-height-15 {
	min-height: 15rem;
}

.h-margin-auto {
	margin-right: auto;
	margin-left: auto;
}

// no print
@media print {
	.h-no-print,
	.h-no-print * {
		display: none !important;
	}

	.h-print {
		display: block !important;
	}
}

// Flex

.h-flex--wrap {
	flex-wrap: wrap;
}

.h-align--v-center {
	display: flex;
	align-items: center;
}

.h-align--h-center {
	display: flex;
	justify-content: center;
}

.h-justify--space-between {
	display: flex;
	justify-content: space-between;
}

@include init-breakpoint-helpers($breakpoints-grid);


// Visual effects
.h-loading {
	position: relative;

	&::before {
		content: '\f110';
		position: absolute;
		top: calc(50% - 0.5em);
		left: calc(50% - 0.5em);
		z-index: 1001;
		animation: fa-spin 2s infinite linear;
		font-family: $font-family-fa;
		font-size: 6rem;
		color: $color-primary;
		text-align: center;
	}

	.loadingWrap {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			background-color: $color-grey-light;
		}

		> * {
			filter: blur(3px);
		}
	}

}

.h-scrollable {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 19px;
		height: 19px;
	}

	&::-webkit-scrollbar-button {
		width: 2px;
		height: 2px;
	}

	&::-webkit-scrollbar-thumb {
		border: solid 5px $color-grey-light;
		border-radius: 50px;
		background: $color-primary;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: $color-primary;
	}

	&::-webkit-scrollbar-thumb:active {
		background: $color-secondary;
	}

	&::-webkit-scrollbar-track {
		border: 9px none #ffffff;
		background: $color-grey-light;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-track:hover {
		background: $color-grey-light;
	}

	&::-webkit-scrollbar-track:active {
		background: $color-grey-light;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	& + div {
		padding-top: 2em;
	}
}

.h-scrollableX {
	white-space: nowrap;
}
