@import 'variables/general.scss';
@import 'variables/colors';
@import 'variables/typography';
@import 'variables/grid';

// utilities
@import 'utilities/functions';
@import 'utilities/mixins';
@import 'utilities/breakpoints';
@import 'utilities/spacing';
@import 'utilities/helpers';
@import 'utilities/animate';

html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-family: $font-family-paragraph;
    -webkit-font-smoothing: antialiased;
}

body {
    position: relative;
    overflow-x: hidden;
    overflow-y: inherit;
    font-size: 0.92em;
}

.open-popup {
    &, body {
        height:auto;
    }

}

.print-only {
    display:none;
}

section {
    background-size: cover;
}

::selection {
    color: white;
}

.wrapper {
    min-height: 100%;
    margin: 0 auto 0; /* the bottom margin is the negative value of the footer's height */

	&.template-filiale{
		&.filiale-exploitation-forestiere-jrbr{
			.logo{
				a{
					img{ max-height:115px; }
				}
			}
		}
	}
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

/* Containers */

.container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    text-align: left;
    position: relative;
}

.nav-inner,
.inner {
    width: auto;
    height: auto;
    max-width: 950px;
    position: relative;
    margin: 0 auto;
}

.inner {
    padding: 80px 0;
}

.inner:after,
.inner:before {
    clear: both;
}

.no-sidebar .inner {
    max-width: 1220px;
}

/* Background Colors */

::selection {
    background-color: $color-primary;
}

/* remplacer loader */
#pageloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 99999;
    background-color: #000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    display:none !important;

    .dark-border {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
    }
}
.mfp-close-btn-in .mfp-close {
    font-size:48px !important;
    color:#000 !important;
    line-height: 52px;
    &:hover {
        color:#fff !important;
    }
    &:active {
        top: 0px;
        font-size:42px !important;
    }
}

@import 'legacy/formats';
@import 'legacy/header';
@import 'legacy/footer';
@import 'legacy/bloc';
@import 'legacy/form';
@import 'legacy/module.emploi';
@import 'legacy/responsive';
@import 'legacy/print';

@import 'components/btn';
@import 'components/filter';
@import 'components/input';
