@mixin init-breakpoint-helpers($breakpoints) {
    @each $breakpoint, $width in $breakpoints {
        @if ($width == 0) {
            @include make-text-alignments($breakpoint);
        }
        
        @else {
            @media (min-width: $width) {
                @include make-text-alignments($breakpoint);
            }
        }
    }
}

@mixin mediaquery-range($min, $max) {
    $query: mediaquery($max, $min);

    @media screen and #{$query} {
        @content;
    }
}

@mixin mediaquery-breakpoint($size, $side:false, $offset:0) {
    $size: $size + $offset;

    $query: mediaquery(0, $size);

    @if ($side == true) {
        $query: mediaquery($size - 1px, 0);
    }

    @media screen and #{$query} {
        @content;
    }
}

@mixin range($property, $min, $max, $range-start, $range-end, $extend:false) {
    $diff: strip-unit($max - $min);
    $range-diff: strip-unit($range-end - $range-start);

    @include mediaquery-range($range-start, $range-end) {
        #{$property}: calc(#{$min} + #{$diff} * ((100vw - #{$range-start}) / #{$range-diff}));
    }

    @if ($extend == true) {
        @include mediaquery-breakpoint($range-end, false) {
            #{$property}: $max;
        }

        @include mediaquery-breakpoint($range-start+1, true) {
            #{$property}: $min;
        }
    }
}

// devices
@mixin screen() {
	@media screen {
		@content;
	}
}

@mixin print() {
	@media print {
		@content;
	}
}

// orientation
@mixin landscape() {
	@media (orientation: landscape) {
		@content;
	}
}

@mixin portrait() {
	@media (orientation: portrait) {
		@content;
	}
}

// interaction
@mixin mouse() {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
}

@mixin touch() {
	@media (hover: none) and (pointer: coarse) {
		@content;
	}
}

// media queries
@mixin xs($offset:0, $side:false) {
    $size: breakpointsize($xs);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin sm($offset:0, $side:false) {
    $size: breakpointsize($sm);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin md($offset:0, $side:false) {
    $size: breakpointsize($md);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin lg($offset:0, $side:false) {
    $size: breakpointsize($lg);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin xl($offset:0, $side:false) {
    $size: breakpointsize($xl);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin xl2($offset:0, $side:false) {
    $size: breakpointsize($xl2);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin hd($offset:0, $side:false) {
	$size: breakpointsize($hd);

	@include mediaquery-breakpoint($size, $side, $offset) {
		@content;
	}
}

@mixin wqhd($offset:0, $side:false) {
	$size: breakpointsize($wqhd);

	@include mediaquery-breakpoint($size, $side, $offset) {
		@content;
	}
}

@mixin uhd($offset:0, $side:false) {
    $size: breakpointsize($uhd);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin mob($offset:0, $side:false) {
    $size: breakpointsize($mob);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin mob-mu($offset:0, $side:false) {
    $size: breakpointsize($mob-mu);

    @include mediaquery-breakpoint($size, $side, $offset) {
        @content;
    }
}

@mixin xs-($offset:0) {
    @include xs($offset, true) {
        @content;
    }
}

@mixin sm-($offset:0) {
    @include sm($offset, true) {
        @content;
    }
}

@mixin md-($offset:0) {
    @include md($offset, true) {
        @content;
    }
}

@mixin lg-($offset:0) {
    @include lg($offset, true) {
        @content;
    }
}

@mixin xl-($offset:0) {
    @include xl($offset, true) {
        @content;
    }
}

@mixin xl2-($offset:0) {
    @include xl2($offset, true) {
        @content;
    }
}

@mixin hd-($offset:0) {
    @include hd($offset, true) {
        @content;
    }
}

@mixin wqhd-($offset:0) {
	@include wqhd($offset, true) {
		@content;
	}
}

@mixin uhd-($offset:0) {
	@include uhd($offset, true) {
		@content;
	}
}

@mixin mob-($offset:0) {
    @include mob($offset, true) {
        @content;
    }
}

@mixin mob-mu-($offset:0) {
    @include mob-mu($offset, true) {
        @content;
    }
}

// range from sm
@mixin sm-md() {
    @include mediaquery-range($sm, $md) {
        @content;
    }
}

@mixin sm-lg() {
    @include mediaquery-range($sm, $lg){
        @content;
    }
}

@mixin sm-xl() {
    @include mediaquery-range($sm, $xl){
        @content;
    }
}

@mixin sm-xl2() {
    @include mediaquery-range($sm, $xl2){
        @content;
    }
}

@mixin sm-hd() {
    @include mediaquery-range($sm, $hd){
        @content;
    }
}

@mixin sm-wqhd() {
    @include mediaquery-range($sm, $wqhd){
        @content;
    }
}

@mixin sm-uhd() {
    @include mediaquery-range($sm, $uhd){
        @content;
    }
}

// range from md
@mixin md-lg() {
    @include mediaquery-range($md, $lg){
        @content;
    }
}

@mixin md-xl() {
    @include mediaquery-range($md, $xl){
        @content;
    }
}

@mixin md-xl2() {
    @include mediaquery-range($md, $xl2){
        @content;
    }
}

@mixin md-hd() {
    @include mediaquery-range($md, $hd){
        @content;
    }
}

@mixin md-wqhd() {
    @include mediaquery-range($md, $wqhd){
        @content;
    }
}

@mixin md-uhd() {
    @include mediaquery-range($md, $uhd){
        @content;
    }
}

// range from lg
@mixin lg-xl() {
    @include mediaquery-range($lg, $xl){
        @content;
    }
}

@mixin lg-xl2() {
    @include mediaquery-range($lg, $xl2){
        @content;
    }
}

@mixin lg-hd() {
    @include mediaquery-range($lg, $hd){
        @content;
    }
}

@mixin lg-wqhd() {
    @include mediaquery-range($lg, $wqhd){
        @content;
    }
}

@mixin lg-uhd() {
    @include mediaquery-range($lg, $uhd){
        @content;
    }
}

// range from xl
@mixin xl-xl2() {
    @include mediaquery-range($xl, $xl2){
        @content;
    }
}

@mixin xl-hd() {
    @include mediaquery-range($xl, $hd){
        @content;
    }
}

@mixin xl-wqhd() {
    @include mediaquery-range($xl, $wqhd){
        @content;
    }
}

@mixin xl-uhd() {
    @include mediaquery-range($xl, $uhd){
        @content;
    }
}

// range from xl2
@mixin xl2-xxxl() {
    @include mediaquery-range($xl2, $xxxl){
        @content;
    }
}

@mixin xl2-hd() {
    @include mediaquery-range($xl2, $hd){
        @content;
    }
}

@mixin xl2-wqhd() {
    @include mediaquery-range($xl2, $wqhd){
        @content;
    }
}

@mixin xl2-uhd() {
    @include mediaquery-range($xl2, $uhd){
        @content;
    }
}

// range from hd
@mixin hd-wqhd() {
    @include mediaquery-range($hd, $wqhd){
        @content;
    }
}

@mixin hd-uhd() {
    @include mediaquery-range($hd, $uhd){
        @content;
    }
}

// range from wqhd
@mixin wqhd-uhd() {
    @include mediaquery-range($wqhd, $uhd){
        @content;
    }
}



/*

Boostrap mixins

*/

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next(
    $name,
    $breakpoints: $breakpoints-grid,
    $breakpoint-names: map-keys($breakpoints-grid)
) {
    $n: index($breakpoint-names, $name);

    @return if(
        $n != null and $n < length($breakpoint-names),
        nth($breakpoint-names, $n + 1),
        null
    );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $breakpoints-grid) {
    $min: map-get($breakpoints, $name);

    @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $breakpoints-grid) {
    $next: breakpoint-next($name, $breakpoints);

    @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $breakpoints-grid) {
    @return if(breakpoint-min($name, $breakpoints) == null, '', '--#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $breakpoints-grid) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }
    
    @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $breakpoints-grid) {
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }
    
    @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
    $lower,
    $upper,
    $breakpoints: $breakpoints-grid
) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    
    @else if $max == null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    }
    
    @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $breakpoints-grid) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    
    @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    }
    
    @else if $min == null {
        @include media-breakpoint-down($name, $breakpoints) {
            @content;
        }
    }
}
