.bouton, button, input[type="button"] {
    @include prefix(box-sizing, border-box);
    @include basictransition(300ms);
    min-height:35px;
    background-color: $color-primary;
    border: solid 1px $color-primary;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    white-space:nowrap;;

    text-shadow: none;
    color: #fff !important;
    text-align: center;
    font-size: 0.9em !important;
    font-weight: 600;
    padding: 10px 60px 8px;
    height: 16px;
    line-height: 16px;
    vertical-align: middle;

    &:hover {
        background-color: #000 !important;
        border-color: #000 !important;
        color: #fff;
        text-decoration: none;

		&.contactIcon{
			background-image:url(~src/images/icon-contact.png) !important;
			background-position:right 15px center !important;
			background-repeat: no-repeat !important;
		}
    }
    &:focus {
        color: #fff;
    }
    &[disabled] {
        opacity: 0.25;
        cursor: default;
        background-color: #000 !important;
        &:hover {
            background-color: #000 !important;
        }
    }

	&.noir{
		border-color:#000000 !important;
		background-color:#000000 !important;
		color:#ffffff !important;
	}

	&.contactIcon{
		padding-left:22px;
		padding-right:42px;
		background-image:url(~src/images/icon-contact.png) !important;
		background-position:right 15px center !important;
		background-repeat: no-repeat !important;
	}
	&.right{ float:right; }

    &.rouge {
        background-color: $color-primary !important;
        border: solid 1px $color-primary !important;
        padding: 10px 60px 8px !important;
    }
}
