// stylelint-disable declaration-no-important

/*  Prefixes
    Margin: m, my, mx, mt, mr, mb, ml
    Padding: p, py, px, pt, pr, pb, pl

    Examples
    .m-2
    .m--xl-4
    .mt--sm-n2
*/

// Margin and Padding

@each $breakpoint in map-keys($breakpoints-helpers) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints-helpers);

        @each $prop, $abbrev in (
            margin: m,
            padding: p
        ) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }

                // .#{$abbrev}y#{$infix}-#{$size},
                .#{$abbrev}t#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }

                // .#{$abbrev}x#{$infix}-#{$size},
                .#{$abbrev}r#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }

                // .#{$abbrev}y#{$infix}-#{$size},
                .#{$abbrev}b#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }

                // .#{$abbrev}x#{$infix}-#{$size},
                .#{$abbrev}l#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size, $length in $spacers {
            @if $size != 0 {
                .m#{$infix}-n#{$size} {
                    margin: -$length !important;
                }

                // .my#{$infix}-n#{$size},
                .mt#{$infix}-n#{$size} {
                    margin-top: -$length !important;
                }

                // .mx#{$infix}-n#{$size},
                .mr#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }

                // .my#{$infix}-n#{$size},
                .mb#{$infix}-n#{$size} {
                    margin-bottom: -$length !important;
                }

                // .mx#{$infix}-n#{$size},
                .ml#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }
            }
        }

        // Some special margin utils
        
        /*
        .m#{$infix}-auto {
            margin: auto !important;
        }

        //.my#{$infix}-auto,
        .mt#{$infix}-auto {
            margin-top: auto !important;
        }

        // .mx#{$infix}-auto,
        .mr#{$infix}-auto {
            margin-right: auto !important;
        }

        // .my#{$infix}-auto,
        .mb#{$infix}-auto {
            margin-bottom: auto !important;
        }

        // .mx#{$infix}-auto,
        .ml#{$infix}-auto {
            margin-left: auto !important;
        }
        */
    }
}
