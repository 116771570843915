.modeMobile, .modeMobileCompact { display: none; }



@media only screen and (max-width: 1295px) {
    .filiales-list {
		li {
			&:nth-child(5n+1):not(:first-child){
				&:before{
					content:none;
				}
			}
		}
	}
}

@media only screen and (max-width: 1250px) {
    .sideTitle { display: none !important; }
}

@media only screen and (max-width: 1185px) {
    .blocEquipe {
		table{
			display:block;
			text-align:center;

			td{
				display:none;
				vertical-align:top;

				&.membreEquipeCell{
					display:inline-block;
					margin:0 10px 20px;
				}
			}
		}
	}

	.blocArbec{
		td{
			display:none;
			vertical-align:top;

			&.usineArbecCell{
				display:inline-block;
				margin:0 10px 20px;
			}

			&.title{
				display:block;

				.locationArbec{ margin-left:0; }
			}
		}
	}
}

@media only screen and (min-width: 1210px) {
    .modeMobile { display: none !important; }
}

@media only screen and (max-width: 1210px) {
    .modeDesktop { display: none !important; }
    .modeMobile { display: block; }

    .sideTitle .inner {
        > h1,
        > div.contain {
            width: auto;
            float: none;
            padding: 0;
        }
    }
    .gros_titre, h1 {
        font-size: 2.6em !important;
    }

    #bandeauHome,
    #bandeauPage {
        .inner { padding: 0 30px; }
        .slide { background-size: 900px auto; }
        .slide-static {
            vertical-align: top;
            top: 0;
            position: absolute;
            bottom: inherit !important;
            left: inherit;
            width: 100%;
            line-height: normal;
            z-index: 101;
            text-align: center;
        }
        #bandeauTextes {
            vertical-align: top;
            top: 0;
            .slideText {
                vertical-align: bottom;
                text-align: center; line-height: 412px;
                .inner { vertical-align: bottom; padding: 20px 30px; font-size: 2.8em; text-align: center; }
            }
        }
    }
    #bandeauPage {
        .inner {
            text-align: center;
            padding: 0px 30px;
            h1 {
                font-size: 2.8em;
                text-align: center;
            }
        }
    }

    .inner { width: auto; padding: 40px 30px; }

    .logo {
        left: 40px;
        top: 25px;
    }

    footer {
        > .inner {
            vertical-align: middle;
            text-align: right;
            text-align: center;
            padding:0 5px !important;

            > .agence {
                display: block;
                text-align: center;
                vertical-align: middle;
                float: none;
            }
            > .copyright {
                display: block;
                text-align: center;
                vertical-align: middle;
            }
        }
    }

    .negativeTop {
        margin-top:-20px;
    }
    .negativeBottom {
        margin-bottom:-20px;
    }

    .articles,
    .double,
    .doubleBleed {
        .contain {
            width: auto;
        }
        article {
            width: auto;
        }
    }
    .tp-banner-container {
        .tp-caption {
            margin: 30px 30px 120px !important;
            width: auto;
        }
    }

    #bandeauPageContainer #bandeauPage {
        padding: 30px 0 20px;
        h1 { font-size: 1.6em; }
    }
    .sidePicture,
    .contactForm,
    .careerForm {
        .col2 {
            padding: 0 !important;
            input,
            textarea {
                width: 100%;
            }
        }
    }


    header {
        height:100%;
        right:-100%;
        &.click {
            right:0;
        }
    }
    .menuTriggerBtn {
        color: $color-primary;
        font-weight: bold;
        line-height: 20px;
        right: 0px;
        top: 0px;
        margin-left: auto;
        vertical-align: middle;
        display: block;
        position: absolute;
        text-align: center;
        padding: 50px 70px;
        background-image: url('~src/images/icon_menu.png') !important;
        background-repeat: no-repeat;
        background-position: 50%;
        pointer-events: auto;
        z-index:9999;

        &:hover {
            color: #fff;
            text-decoration: none;
            background-repeat: no-repeat;
            background-image: url('~src/images/icon_menu.png') !important;
        }
        &.click {
            background-color: $color-primary-dark;
            color: #fff !important;
            background-image: url('~src/images/icon_menu_white.png') !important;
        }
    }

    .nav-menu {
        height: 100%;
        overflow:hidden;
    }

    .menuLangue {
        display:none;
    }

    // Root Container
    .menuPrincipal {
        @include basictransition(500ms);
        height: 100%;
        line-height: 44px;
        display: block;
        font-weight: normal;
        font-size: 14px;
        text-transform: uppercase;
        position: relative;
        top: 90px;
        white-space: nowrap;
        width: calc(100vw - 270px);
        max-height: 100vh;
        margin-right:0px;

        .menuTriggerBtn {
            z-index:9999;
        }

        .click {
            .menuTriggerBtn {
                z-index:0;
            }
        }

        .menuLangue {
            display:block;
            position:absolute;
            top:0;
            left:20px;
                height:auto !important;
                padding:10px !important;
                margin:0 !important;
                line-height:1em !important;
            > li {
                height:auto !important;
                padding:0 !important;
                margin:0 !important;
                line-height:1em !important;
                a {
                    height:auto !important;
                    padding:15px !important;
                    margin:0 !important;
                    line-height:1em !important;
                    padding:10px;
                    color:#fff;
                    line-height:inherit;
                    &:hover {
                        background:#000;
                    }
                }
            }
        }

        // Root
        > ul {
            display: inline-block;
            position: relative;
            // Root item
            > li.item {
                display: inline-block;
                margin: 0;
                padding: 0;

                a {
                    font-weight: bold;
                    font-size: 0.9em;
                    color: #000;
                    padding: 0 0 0 25px;
                    display: inline-block;
                    vertical-align: top;
                    pointer-events: auto;
                    &:hover,
                    &:active,
                    &:focus { color: $color-primary; text-decoration: none; }
                }
                span { vertical-align: middle; padding-bottom: 9px; }

                &:hover {
                    a { color: $color-primary;
                        span { border-bottom: solid 5px $color-primary; }
                    }
                    > ul {
                        max-height: 800px !important;
                    }
                }

                // Sous menu
                > ul {
                    max-height: 0 !important;
                    overflow: hidden;
                    @include basictransition(500ms);
                    position: absolute;
                    text-align: left;

                    > li {
                        padding: 0px;
                        line-height: normal;
                        height: auto;
                        > a {
                            padding: 6px 15px;
                            display: block;
                            background: #000;
                            border-left: solid 1px $color-primary;
                            border-right: solid 1px $color-primary;
                            border-bottom: solid 1px $color-primary;
                            height: 43px;
                            line-height: 43px;
                            pointer-events: auto;

                            &:hover { background: $color-primary; }
                        }
                    }
                    > li.active {
                        a { opacity: 1; background: $color-primary; }
                    }
                }
                &.active {
                    a { }
                }
            }
        }

        list-style: none;
        height: 100%;
        line-height: 94px;
        top: 0;

        // ROOT
        > li {
            position: static;
            float: left;
            border: 0;
            float: none;
            margin: 0 !important;
            list-style: none;

            > div {
                // all menus
                > ul {
                    @include basictransition(500ms);
                    height: 100%;
                    top: 0px;
                    right: 0px;
                    opacity: 1;
                    border-radius: 0;
                    background: $color-primary;
                    font-size: 1.2em;
                    position: absolute;
                    left: inherit;
                    padding: 0;
                    border-radius: 0;
                    text-align: center;
                    padding: 20px 0;
                    position: absolute !important;
                    width: 100%;
                    display: none;
                    text-align: left;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding-top: 170px;
                    display: block;
                    right: 0;
                    opacity:0;

                    // element menu
                    > li {
                        display: block !important;
                        position: relative !important;
                        width: 100%;
                        > a {
                            font-weight:600;
                            text-align: center;
                            padding: 17px 30px 11px 30px;
                            display: block;
                            line-height: 38px;
                            background: $color-primary-dark;
                            color: #fff;
                            span { padding-bottom: 4px; }
                            margin-bottom: 1px;
                            pointer-events: auto;
                            text-decoration: none;
                        }
                        > a:hover {
                            text-decoration: none;
                            color: #fff;
                        }
                    }
                    > li.active {
                    }
                    > li:hover,
                    > li.click {
                        a {
                            color: #fff !important;
                            background-color: #000;
                        }
                    }

                    > li.menu {
                        /*background-image: url('~src/images/menu_close.png');*/
                        background-position: 96% 14px;
                        background-repeat: no-repeat;
                        > a {
                            padding-left: 40px;
                        }
                    }
                    > li.menu.click {
                        /*background-image: url('~src/images/menu_open.png');*/
                    }

                    // sous menu
                    > li > ul {
                        @include basictransition(500ms);
                        display: block;
                        position: relative !important;
                        top: inherit;
                        left: inherit;
                        width: 100%;
                        text-align: left;
                        padding: 3px 0 10px;
                        background: $color-primary-dark;
                        display: none;
                        right: -0px;

                        // element sous menu
                        > li {
                            display: block !important;
                            position: relative !important;
                            width: 100%;
                            > a {
                                display: block;
                                color: #fff;
                                padding: 8px 30px 8px 60px;
                                line-height: 14px;
                                font-size: 0.9em;
                                text-transform: none;
                            }
                            > a:hover {
                                background: $color-primary-darker;
                                color: #fff;
                                text-decoration: none;
                            }
                        }

                        > li.active {
                            > a {
                                background: $color-primary-darker;
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            div.click {
                > a {
                    background-color: $color-primary-dark;
                    color: #fff !important;
                    background-image: url('~src/images/icon_menu_white.png') !important;
                }
                > ul {
                    right: 0px;
                    opacity:1;
                }
            }
        }
        .menuClose {
            display: inline-block;
            position: absolute;
            top: -100px;
            right: 20px;
            font-size: 3em;
            text-transform: lowercase;
            background: transparent !important;
        }
    }


    .template-filiale,
    .template-equipe,
    .template-page {

        &.template-page,
        &.template-equipe {
            #enteteSlideshow {
                height: 43vh;
            }
            .blocAbout {
                height: 40vh;
                line-height: 40vh;
            }
        }

        &.template-page {
            .bloc-carriere {
                border-top: 1px solid #d9b67e;
            }
        }

        #enteteSlideshow {
            margin-top: 0;
            .slide {
                height:calc(53vh - 140px) !important;
            }
            #bandeauImages {
                top:140px;
            }
        }
    }
    .filiales-list {
        margin-top: 40px;
        margin-bottom: 50px;
        li {
            margin: 20px auto;
        }
    }
}
@media only screen and (min-width: 940px) {
    .nomenu .menuTriggerBtn {display:none !important;}
}
@media only screen and (max-width: 940px) {
    .modeMobileCompact { display: block; }

    .inner { width: auto; padding: 30px 25px !important; }
    .blocArea {
        > .blocRight { display: none; }
    }
    .double {
        .col {
            width: auto;
            float: none;
            padding: 10px 0;
        }
    }

    .articles,
    .double,
    .doubleBleed {
        .contain {
            width: 100%;
        }
        article {
            padding: 40px;
            width: auto;
            margin-right: 0;
            margin-bottom: 40px;
            display: block;
        }
    }
    .doubleBleed {
        article { padding: 0px; }
    }

    .container {
        .floatRight,
        .floatLeft {
            float: none !important;
            display: block;
            margin: 30px auto;
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: 100%;
        }
    }

    .sidePicture,
    .contactForm,
    .careerForm {
        .col {
            width: auto;
            display: block !important;
            > div {
                width: auto !important;
                max-width:inherit;
            }
        }
        .inner {
            max-width: inherit !important;
        }
        .col1 {
            > div { margin: 0 !important; }
            .inner {
                padding: 40px 30px 60px !important;
            }
            .photo {
                float:left !important;
                margin:0 10px 0 0 !important;
            }
        }
        .col2 {
            padding: 0 !important;
            clear: both;
            height: auto !important;
            width: 100%;
            float: none;
            display: block;
            background-position: top center;
            background-size: 100%;
            .inner {
                padding-top: 0 !important;
                padding-bottom: 20px !important;
                h2 {
                    padding-top: 0;
                    margin-top: 0;
                }
            }
            input,
            textarea {
                width: 100%;
            }
        }
        .col-spacer { display: none !important; }

        table, tbody, tr, td {
            display: block !important;
            width: auto !important;
        }
    }

    #map {
        position: fixed;
        top: 0;
        left: 0;
        height: 100% !important;
        z-index: 10000;
        > div { height: 100%; }
    }
    .container.blocFaitesCarriere {
        .columns3 {
            .col {
                padding: 10px 0 !important;
                clear: both;
                height: auto !important;
                min-height:0px;
                width: 100%;
                float: none;
                display: block;
                border: none;
            }
        }
        .carriere-sous-titre {
            width:100%;
            float:none;
        }
        .carriere-button-postule {
            margin:10px 0 15px;
            float:none;
        }
    }
    #enteteSlideshow {
        .gros_titre {
            font-size: 34px !important;
        }
        .tp-caption {
            > div {
                bottom:0 !important;
                left:10px !important;
                right:inherit !important;
            }
        }
        .bouton {
            font-size:16px !important
        }
    }

	.blocEquipe {
		margin-top:20px;
	}

    .blocArea .blocLeft {
        display:block;
    }
    .menuCieContainer {
        @include basictransition(500ms);
        display: block !important;
        position: absolute !important;
        bottom: 0;
        left: -300px;
        border-right:solid 1px #fff;
        top: 0;
        max-height: 100vh;
        &.click {
            height: auto;
            margin: 0;
            bottom: 0;
            left: 0;
            .mediaCie {
                a {
                    width: 50%;
                    display: inline-block;
                }
            }
        }
    }
    .logo {
        margin-left: 30px;
        margin-right: 30px;
    }
    .template-societe .blocLeft .blocAbout .inner {
        padding-bottom:50px;
    }
    #map-fixed {
        width:100%;
    }

    .blocAbout {
         height:auto !important;
    }

    .bloc-carriere  {
        .inner {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .bloc-carriere {
        background-size: 100%;
        background-repeat: repeat-y;
    }

    .bloc-header {
        min-height:auto;
    }
    #enteteSlideshow ul {
        height: calc(100% - 140px) !important;
        top:140px !important;
        background-size:cover !important;
        left:0 !important;
        display:block !important;
        position:relative !important;
        background-position:center !important;
        opacity:1 !important;
        li {
            .tp-bgimg {
                background-size:cover !important;
                left:0 !important;
                display:block !important;
                position:relative !important;
                background-position:center !important;
            }
            .slotslide {
                div {
                    width:100% !important;
                    height: calc(100% - 140px) !important;
                    background-position:center !important;
                }
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    .mfp-container {
        padding:0;
    }
}
@media only screen and (max-width: 750px) {
    .blocAbout{
        .columns2 {
            .col {
                padding: 0 !important;
                clear: both;
                height: auto !important;
                min-height:0px;
                width: 100%;
                float: none;
                display: block;
                border: none;
            }
        }
    }
    .columns3, .columns2 {
        .col {
            padding: 0 !important;
            clear: both;
            height: auto !important;
            min-height:0px;
            width: 100%;
            float: none;
            display: block;
            border: none;
            margin-left:0 !important;
            margin-right:0 !important;
            margin-bottom:40px;
            ul {
                margin:0;
            }
            article {
                height:auto !important;
            }
        }
    }
    .facts  {

    }
    .container.blocFaitesCarriere .carriere-button-postule {
        width:auto;
    }
    .blocLeft .facts dl {
        display:inline-block;
        width:49% !important;
        vertical-align:middle;
    }
    .template-filiale #enteteSlideshow, .template-equipe #enteteSlideshow, .template-page #enteteSlideshow {
        height:55vh !important;
        img {
            padding-top: 120px;
        }
    }


    .tipsy, .tipso_bubble, .usine-info {
        display:none !important;
    }
    .usineArbec {
        img, .usine-info {
            cursor:pointer;
        }
        &.active {
            .usine-info {

                top: 2.5% !important;
                left: 5% !important;
                position: fixed !important;
                width: 90% !important;
                height: 95% !important;
                display: block !important;
                z-index: 2000;
                overflow:scroll;
            }
        }
    }

    .template-emploi {
        .module-emploi  {
            .menu-emploi {
                > a {
                    display: inline-block;
                    width: 50%;
                    margin-bottom: 1px;
                }
            }
            .col {
                width:auto !important;
            }

            &.module-emploi-categories .col-3 {
                display: block;
                .col {
                    display: block;
                    width:auto;
                    border-right:none;
                    padding-left:0;
                    margin-bottom:40px;
                }
            }

            .col-2 .col:nth-child(2) {
                padding-left:0;
                margin-top:10px;
            }

            &.module-emploi-profile {
                form .blocInfo .row label {
                    input, select {
                        width: 100%;
                    }
                }

            }
        }
        .module-emploi.module-emploi-login {
            #login-register {
                display:block !important;
                height:auto;
                border:none;
                background:none;
                height:20px;
            }
            #login-form {
                display:block;
                margin-left:0 !important;
                width:100% !important;
                margin-top: 70px;
                h2 {top:-2em;}
                label span {
                    display:block !important;
                }
                .label > span {
                    display:None;
                }
            }
        }
    }
    .bloc-equipements {
        .columns3 .col,
        .columns2 .col {
            margin-bottom:0 !important;
        }
    }
}



@media only screen and (max-width: 655px) {
    .bloc-commandite{
        .blocSombre .zone-bouton {
            height:auto;
            background:none;
            padding:0;
            .lien {
                display:block;
                padding:0;
            }
            .bouton {
                width:100%;
                white-space: normal;
                height: auto;
            }
        }
    }

    .mouse_scroll {
        display:none;
    }
    #enteteSlideshow .tp-caption {
        margin-bottom:0 !important;
        width: calc(100% - 50px) !important;
    }
}

@media only screen and (max-width: 540px) {
    .gros_titre, h1 {
        font-size: 1.6em !important;
    }
    .moyen_titre, h2 {
        font-size: 1.3em !important;
    }
    #bandeauPage {
        .inner {
            h1 {
                font-size: 1.2em !important;
            }
        }
    }
    #contact {
        .photo-spacer {
            display:none;
        }
    }
    .sidePicture,
    .contactForm,
    .careerForm {
        .col {
            > div {
            }
        }
        .inner {
        }
        .col1 {
            > div {
                margin: 0 !important;
            }
            .inner {
            }
            .photo {
                width: auto !important;
            }
            .address-bloc {
                .info {
                    margin: 30px 0 20px;
                    width: 100% !important;
                    display: inline-block;
                }
            }
        }
    }
    .blocArea .blocRight {
        width: 210px;
    }
    .menuPrincipal {
        width: calc(100vw - 210px);
    }

    #equipe {
        table {
            width:auto;
            margin:0 25px;
            tbody {
                width:auto;
                tr {
                    width:auto;
                    td {
                        border:none;
                        margin: 0;
                        height: auto;
                        width: 100%;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        .photo {
                            display: block;
                            width:auto;
                            img {width:100%; max-width:216px;}
                        }
                        .membre-info {
                            width:auto;
                            height:auto;
                            padding-bottom: 22px;
                            padding-left:0;
                            padding-top: 10px;
                        }
                        .membreEquipe {
                            width:auto;
                            height:auto;
                            margin-bottom:20px;
                        }
                    }
                }
            }
        }
    }
    .genericPopup,
    #formContactPopup,
    #formCommandite{
        padding:30px;
        box-sizing: border-box;
        width:100%;
    }
    .popupOffre,
    .genericFormBloc {
        padding-bottom:120px;
        .offre-tools-print {
            left: 30px;
        }
        .offre-tools {
            right:30px;
        }
        .row label,
        .row .info {
            display:block;
            width:auto;
        }
        .row .info {
            margin-bottom:10px;
        }
    }
    .template-emploi .module-emploi .offreUnic {
        .offre-tools {
            position:relative;
            text-align:left;
            float:none;
            .socials {
                float:none;
                li:first-child {
                    margin-left:0;
                }
            }
        }
    }
    #formCommandite{
        width:100%;
        .step-1 {
            overflow:hidden;
        }
        .border {
            padding:30px 25px;
        }
        .checkbox-group,
        .radio-group{
            label {
                display: block;
                margin:5px 0 !important;
            }
        }
    }
    .bloc-articles-list {
        .articles-categories {
            width: auto;
            height: auto;
            display: block;
            margin: 10px 0;

            .bouton {
                display: block;
                width: auto;
                height: auto;
                margin-bottom: 1px;
            }
        }
        .media_container {
            article {
                border:none;
                padding:0;
            }
        }
        .bouton.contactIcon {
            margin-top:0;
        }
    }
}


@media only screen and (max-width: 450px) {
    .blocArea .blocRight {
        width: 150px;
    }
    .menuPrincipal {
        width: calc(100vw - 150px);
        > li > div > ul {
            padding-top: 90px;
            & > li a {
                font-size: 0.85em;
            }
        }
        .menuClose {
            right: -18px;
            top: -91px;
        }
    }
    .menuCie a li {
        font-size:1em;
        padding-left:5px;
        padding-right:5px;
    }
    .logo {
        margin-left:15px;
        margin-right:15px;
        a img {
            max-width: 200px;
        }
    }
    .menuTriggerBtn {
        padding: 60px 40px;
    }
    .blocLeft .facts dl {
        width:100% !important;
    }

    .industrie-john-lewis-ltee .logo a img {max-width:200px;}

    #enteteSlideshow {
        .tp-caption {
            > div {
                bottom:0 !important;
                left:-10px !important;
                right:inherit !important;
            }
        }
        .gros_titre {
            font-size:18px !important;
        }
    }
}


@media only screen and (max-width: 410px) {
    .bouton {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    table.blocArbec td .usineArbec {
        padding:0;
    }
}

@media only screen and (max-width: 350px) {
    .bloc-articles {
        article {
            padding-bottom: 90px !important;
            .socials {
                float: none;
                li {
                    margin: 0 18px 0 0;
                    img {max-width:100%;}
                }
            }
        }
    }
    .template-emploi {
        .module-emploi  {
            .menu-emploi {
                > a {
                    width: 100%;
                }
            }
        }
    }
}
