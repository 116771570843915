// theme colors
$color-primary:         #960027 !default;
$color-primary-light:   #960027 !default;
$color-primary-dark:    #a0002a !default;
$color-primary-darker:    #a0002a !default;

$color-secondary: 		#d9b67e !default;
$color-secondary-light: #d9b67e !default;
$color-secondary-dark: 	#182e0d !default;

$color-success:         #28a745 !default;
$color-success-light:   #2bb64b !default;
$color-success-dark:    #21913a !default;

$color-danger: 			#e31415 !default;
$color-danger-light: 	#d33 !default;
$color-danger-dark: 	#901415 !default;

$color-black:           #000000 !default;
$color-black-light:     #2f3d49 !default;
$color-black-dark:      #171e24 !default;

$color-grey: 	        #5b5b5b !default;
$color-grey-light:      #aaafb4 !default;
$color-grey-lighter:    #F2F2F2 !default;
$color-grey-dark: 	    #3d3d46 !default;

$color-white:           #fff !default;

// text colors
$color-text-black: 		$color-black !default;
$color-text-white: 		$color-white !default;
$color-text-grey:       $color-grey-light !default;
$color-text-disabled: 	$color-grey !default;
$color-text: 			$color-text-black !default;
$color-title:           $color-text-black !default;

// link colors
$color-link-base:		$color-primary !default;
$color-link-hover:		$color-grey-light !default;

// colors classes
$colors-class: (
    primary $color-primary,
    secondary $color-secondary,
    grey $color-grey
);
