@import url(https://fonts.googleapis.com/css?family=Hind:400,500,600,700);

@font-face {
	font-family: 'helvetica_blackregular';
	src: url('~src/font/helvetica-black-webfont.woff2') format('woff2'),
	url('~src/font/helvetica-black-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


/* Fonts */
$font-family-title:         'Hind', sans-serif !default;
$font-family-paragraph:     'Hind', sans-serif !default;
$font-family-fa:            'Font Awesome 5 Free', 'Font Awesome 5 Pro', 'Font Awesome 5 Brands', monospace;

$font-size-html:        10px !default;
$font-size-base:        3rem !default;
$font-size-small:       2rem !default;

$font-size-paragraph:   $font-size-small !default;
$line-height-base:      130% !default;
$font-letter-spacing: 0;

$font-weight-light:     300 !default;
$font-weight-regular:   400 !default;
$font-weight-medium:    500 !default;
$font-weight-semibold:  600 !default;
$font-weight-bold:      700 !default;
$font-weight-xbold: 	800 !default;
$font-weight-black: 	900 !default;

$font-weight: $font-weight-regular;
