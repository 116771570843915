/* Navigation Section */

#nav-sticky {
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0;
    margin: 0;
    position: static;
}

.nav-inner {
    height: 100%;
    margin: 0 auto;
    padding: 0;
    z-index:9999;
}

.menuTriggerBtn {
    display:none;
}


/* Mobile Nav Button */

.mobile-nav-button {
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: transparent;
    right: 15px;
    top: 50%;
    text-align: right;
    font-size: 25px;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile-nav-button:hover,
.mobile-nav-button:active WW{
    color: $color-primary;
}

#enteteSlideshow {
    ul { position: relative; margin: 0; padding: 0; }
    li { position: absolute; margin: 0; padding: 0; }
    li::before { display: none !important; }
    font-size: 0.88em !important;
    .tp-caption {
        position: absolute;
        line-height: 2em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .gros_titre {
        margin-bottom: 10px;
    }
}

// Logo
.logo-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    background:#fff;
    width:100%;
    z-index: 21;
}
.logo {
    @include basictransition(500ms);
    float: left;
    z-index: 1;
    margin:10px 90px 10px;
    background-size: 0%;
    a {
        line-height: 120px;
        vertical-align: middle;
        height: 120px;
        display: inline-block;
        img {
            @include basictransition(500ms);
            top: 0px;
            position: relative;
            max-height: 85px;
            max-width:250px;
            vertical-align:middle;
            display:inline-block;
        }
    }
}

//Exeption de taille du logo
.industrie-john-lewis-ltee{
	.logo{
		a{
			img{ max-width:280px; }
		}
	}

}

#contact{
    h4{
        color:$color-secondary;
        font-weight:bold;
    }
}

header {
    background-size: 100%;
    position: absolute;
    top: 0;
    text-align: right;
    z-index: 1000;
    right: 0;
    z-index:99999;
    @include basictransition(500ms);

    .nav-inner, .inner {
        max-width: 1150px;
    }
}

.menuLangue {
    list-style:none;
    margin-right:37px;
    > li {
        font-weight: bold;
        display:inline-block;
        > a {
            color:#000;
            padding:8px 11px;
            text-decoration:none;
            display:inline-block;
            &:hover {
                background:$color-primary;
                color:#fff;
            }
        }
    }
}

// Root Container
.menuPrincipal {
	@include basictransition(500ms);
	height:100%;
	line-height:86px;
	display:block;
	font-weight:normal;
	font-size:15px;
	text-transform:uppercase;
	position:relative;
	white-space: nowrap;
    text-align:right;
    margin-right:45px;

    .menuClose, .menuLangue {
        display:none;
    }

	// Root
	> li > div > ul {
		display:inline-block;
		position:relative;

		// Root item
		> li {
			display:inline-block;
			margin:0;
			padding:0;
			position:relative;

            &.accueil,
            &.contact{
                display:none;
            }

			a {
                text-decoration:none;
				font-weight:bold;
				font-size:1em;
				color:#000;
				padding:0 0 0 35px;
				display:inline-block;
				vertical-align:top;
				&:hover,
				&:active,
				&:focus { text-decoration:none; }
				&.sticky {height:44px;}
			}
			span {vertical-align:middle; padding-bottom:9px; }


			&:hover {
				a {color:$color-primary;}
				> ul {
					max-height: 800px !important;
				}
			}

			// Sous menu
			> ul {
				max-height: 0 !important;
				overflow:hidden;
				@include basictransition(500ms);
				position:absolute;
				left:25px;
				text-align:left;
    			top: 100%;
				-webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
				box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
				min-width:100%;
				> li {
					padding:0px;
					line-height:normal;
					height:auto;
					> a {
						padding:6px 22px;
						display:block;
						background:rgba(0,0,0,0.8);
						color:#000;
						border:none;
						height:43px;
						line-height:43px;
						font-weight:normal;
						text-transform:none;
						span {border:none !important; color:#fff;}

						&:hover {background:$color-primary; span {color:#fff;}}
					}
				}
				> li.active {
					a { opacity:1; background:$color-primary;}
				}
			}
			&.active {
				a {}
			}
		}
	}
}

.menuCie {
    background: #fff url('~src/images/photos/tree.jpg') no-repeat bottom center;
    height: 100%;
    overflow: hidden;
    margin-bottom: -1px;
    overflow:visible;
    a {
        height: 16.6666666vh;
        line-height: 16.6666666vh;
        max-height:200px;
        vertical-align: middle;
        display:block;
        text-align: center;
        border-bottom: solid 1px #fff;
        position: relative;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-left: solid 1px #fff;
        width:calc(100% + 1);
        margin-left:-1px;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: $color-secondary;
            opacity: 0.65;
            @include basictransition(500ms);
        }
        &:hover {
            &:before {
                background: rgba(0,0,0, 0.3);
            }
        }
        &:active {
            opacity: 0.8;
        }
        li {
            width:100%;
            vertical-align:middle;
            color: #fff;
            line-height: 1em;
            max-line-height:200px;
            font-size: 1.5em;
            text-transform: uppercase;
            font-weight: bold;
            display:inline-block;
            padding: 0px 20px;
			margin-top:10px;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: relative;
            text-decoration:none;
            span {
                text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
                position: relative;
                top: -6px;
                vertical-align: middle;
                display: inline-block;
                line-height: 1.2em; /* on retablit le line-height */
            }
        }
        &.groupe-remabec {background-image:url('~src/images/menu/g.jpg');}
        &.rebec {background-image:url('~src/images/menu/a.jpg');}
        &.industrie-john-lewis-ltee {background-image:url('~src/images/menu/c.jpg');}
        &.arbec {background-image:url('~src/images/menu/b.jpg');}
        &.boscus {background-image:url('~src/images/menu/d.jpg');}
        &.lien-filiales {background-image:url('~src/images/menu/e.jpg'); background-position:center center;}
        &.environnement-lien {background-image:url('~src/images/menu/f.jpg');}
        &.energie-verte-lien {background-image:url('~src/images/menu/h.jpg');}
    }
}

// Bandeau
#bandeauPageContainer {
    background: #000;
    padding-top: 120px;
    text-align: center;
}

#bandeauPage {
    font-size: 3em;
    padding-top: 60px;
    .inner {
        padding: 160px 0;
        min-height: 30px;
    }
    .sideTitle {
        display: none;
    }
}

#home {
    .nav-inner { max-width: inherit !important; }
    #enteteSlideshow {
        .bouton {
            background: $color-primary;
            border-color: $color-primary;
            padding-left: 60px;
            padding-right: 60px;
            &:hover {
                background: #000;
                border-color: #000;
            }
        }
    }
}
.bloc-header {
    min-height:100vh;
    .titre-header {display:none;}
}

.menuLangue {
    li {
    }
    li.item {
        display:inline-block !important;
        a {
            font-weight: normal;
        }
    }
}


.mouse_scroll {
    display: block;
    width: 23px;
    height: 38px;
    position: absolute;
    bottom: 35px;
    left: 50%;
    border: 1.5px solid #FFF;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-border-radius: 23px;
    -webkit-border-radius: 23px;
    border-radius: 23px;
    z-index: 9999;
    .mouse_movement {
        display: block;
        width: 3px;
        height: 6px;
        position: absolute;
        top: 25%;
        left: 50%;
        background: #FFF;
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-animation: scroll-ani 2s linear infinite;
        -webkit-animation: scroll-ani 2s linear infinite;
        animation: scroll-ani 2s linear infinite;
	}
}
.tp-banner-container {
    .tp-bullets {
        @include basictransition(500ms);
        opacity:0;
        position: absolute;
        bottom: calc(50% - 15px) !important;
        left: 0px;
        z-index: 1000;
        width: 100%;
        right: inherit;
        &.navbar {
            height: 35px;
            padding: 0px 0px;
        }
        .bullet {
            display:none;
        }
        .tparrows {
            cursor: pointer;
            display: inline-block;
            position: absolute !important;
            padding: 40px;
            opacity:1;
            &:hover, &.selected {
                opacity:0.6;
            }
            &:active {
                opacity:0.3;
            }
        }
        .tp-rightarrow {
            right: 0px;
            &:before {
                position:absolute;
                top:50%;
                right:50%;
                content:"";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 15px 0 15px 26.0px;
                border-color: transparent transparent transparent #ffffff;
            }
        }
        .tp-leftarrow {
            left: 0px;
            &:before {
                position:absolute;
                top:50%;
                left:50%;
                content:"";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 15px 26.0px 15px 0;
                border-color: transparent #ffffff transparent transparent;
            }
        }
    }
    &:hover {
        .tp-bullets {
            opacity: 1;
        }
    }
}

@-webkit-keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 25%
    }
    50% {
        opacity: 1;
        top: 40%
    }
    70% {
        opacity: 0;
        top: 40%
    }
    100% {
        opacity: 0;
        top: 25%
    }
}
@-moz-keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 25%
    }
    50% {
        opacity: 1;
        top: 40%
    }
    70% {
        opacity: 0;
        top: 40%
    }
    100% {
        opacity: 0;
        top: 25%
    }
}
@keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 25%
    }
    50% {
        opacity: 1;
        top: 40%
    }
    70% {
        opacity: 0;
        top: 40%
    }
    100% {
        opacity: 0;
        top: 25%
    }
}
