.filter {
	display: inline-block;

	&__wrap {
		padding-top: 10px;
	}

	&__container {
		position: relative;
		z-index: 200;
	}

	&__icon {
		vertical-align: middle;
		margin-right: 0.5em;
		font-size: 1.3em;
	}

	&__trigger {
		@extend .bouton;
		position: relative;
		vertical-align: middle;
		width: 210px;
		padding-right: 10px !important;
		padding-left: 10px !important;
		background-position: 5px center !important;
		background-size: 15px !important;
		text-align: left;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 14px;
			width: 10px;
			height: 100%;
			background-image: url('~src/images/angle.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: all 500ms ease;
		}
	}

	&__list {
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
		width: 100%;
		padding: 8px 10px 5px;
		border: solid 2px $color-primary;
		background-color: #fff;
		z-index: 10;
	}

	&__items {
		margin: 0 !important;
	}

	&__item {
		display: inline-block;
		width: 276px;
		max-width: 100%;
		margin: 0 !important;
		padding-right: 10px !important;
		padding-bottom: 1px !important;
		padding-left: 26px !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--group {
			width: 100% !important;
		}
	}

	&.active & {
		&__trigger {
			padding-left: 40px !important;
			border-color: $color-secondary-dark;
			background-image: url('~src/images/checkmark.png') !important;
			background-color: $color-secondary-dark;
			background-position: 12px center !important;
			background-repeat: no-repeat !important;
		}
	}

	&.open & {
		&__trigger {
			border-color: $color-primary !important;
			background-color: $color-primary !important;

			&::after {
				transform: rotate(180deg);
			}
		}

		&__list {
			display: block;
		}
	}

	@include xl-() {
		h2 {
			clear: both;
		}

		&__label {
			&--extended {
				display: none;
			}
		}

		&__trigger {
			width: 130px;
		}

		&__item {
			width: 200px;
		}
	}

	@include sm-() {
		position: relative;
		width: 100%;
		margin-top: 2px;
		box-sizing: border-box;

		&__icon {
			display: none;
		}

		&__trigger {
			width: 100%;
		}

		&__list {
			position: relative;
			box-sizing: border-box;
		}

		&__item {
			width: 100%;
			box-sizing: border-box;
		}

		label {
			white-space: normal !important;

			.label {
				margin-left: 0;
			}
		}
	}
}

.module-emploi-recherche {
	min-height: 1290px;

	@include sm-lg() {
		min-height: 1590px;
	}
}
