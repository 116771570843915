footer {
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 9999;
    background: #1B1B1B;
    color: #636363;
    font-size: 0.8em;
    min-height: 45px;

    .privacypolicy {
        text-align: left;
    }

    > .inner {
        padding: 0px 30px 0px !important;
        margin: 0;
        max-width: inherit;
        vertical-align: middle;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 45px;

        p {
            margin: 1em 0;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: #d9b67e;
            }
        }

        > .copyright {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            span { vertical-align: middle; display:inline-block; line-height:45px; }

            .arsenalLogo {
                background: url('~src/images/arsenalweb.png') no-repeat center center;
                display: inline-block;
                width: 115px;
                height: 20px;
                vertical-align: middle;
                &:hover { background-image: url('~src/images/arsenalweb-h.png'); }
                padding: 5px 10px 15px;
            }
        }
    }
    a {
        display: inline-block;
        color: #fff;
    }
}