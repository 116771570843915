// clear
@mixin clear() {
    &::before,
    &::after {
        clear: both;
    }
}

// set background image as centered cover
@mixin bgcover() {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin bgcontain() {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

// set the elements to cover its parent (requires parent to be relative)
@mixin absolutecover($zIndex: 2) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: $zIndex;
}

// set the font size and line-height from rem values
@mixin font-size($font-size: 1, $line-height: $font-size * 1.5) {
    font-size: $font-size-base * $font-size;
    line-height: 1em * $line-height;
}

// change svgs properties
@mixin svg-properties() {
    path,
    circle,
    rect {
        @content;
    }
}

// responsive text alignments
@mixin make-text-alignments($breakpoint) {
    .text--#{$breakpoint}-right {
        text-align: right;
    }

    .text--#{$breakpoint}-left {
        text-align: left;
    }

    .text--#{$breakpoint}-center {
        text-align: center;
    }
}
