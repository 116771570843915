.container a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}

.relative { position: relative !important; }

.semibold { font-weight: 600; }

.uppercase { text-transform: uppercase; }

a { color: $color-secondary; cursor: pointer; }

a:hover { color: $color-primary; text-decoration: none; }

em { font-style: italic; }

strong { font-weight: bold; }

hr { border: none; border-bottom: solid 1px #000; }

.clear { clear: both; }

.gros_titre, h1 { font-size: 40px; text-transform:uppercase; font-weight:bold; }
.moyen_titre, h2 { font-size: 29px; font-weight:bold; }
.petit_titre, h3 { font-size: 19px; font-weight: normal; }

.gros_titre,
.moyen_titre,
.petit_titre { display: inline-block; }

strong {
    .gros_titre { font-weight: 900 !important; word-spacing: 4px; }
}

.gris { color: #989898 !important; }

.noir { color: #000 !important;
    .bar { background: #000 !important; }
}

.bg-dark {background-color:$color-secondary-dark;}
.bg-light {background-color:$color-secondary;}
.couleur-dark {color:$color-secondary-dark;}
.couleur-light {color:$color-secondary;}

.vert { color: #1c3609 !important; }
.rouge { color: $color-primary !important; }
.beige { color: #d9b67e !important; }

.center { text-align: center; }

.plugin_picture {
    &.align-right {
        float:right;
        padding-left:20px;
    }
    &.align-left {
        float:left;
        padding-right:20px;
    }
    &.align-center {
        text-align:center;
    }
}
.plugin_file {
    img,
    .fileicon,
    .filesize {display:none;}
}



.bar {
    width: 140px;
    height: 8px;
    background: $color-primary;
    display: inline-block;
    margin: 6px 10px;
    vertical-align: middle;
}

/* Positions and Floats */

.container {
    .gros_titre,
    h1 {
        text-transform: uppercase;
        font-size: 3.1em;
        line-height: 1.1em;
        padding: 6px 0;
        margin: 5px 0 5px;
        font-weight: bold;
        position: relative;
    }
    .moyen_titre,
    h2 {
        margin: 10px 0 0;
        font-size: 1.7em;
        font-weight: bold !important;
        word-spacing: 2px;
        padding-top: 5px;
        line-height: 1em;
    }
    .petit_titre,
    h3 {
        font-weight: bold;
        font-size: 1.4em;
        line-height:1.4em;
    }
    .bold { font-weight: normal; }
    hr {
        margin: 35px 0px;
        border-color:$color-secondary-dark;
    }
    p, ul {
        line-height: 1.5em;
        margin: 4px 0 10px;
    }
    ul {
        li {
            margin: 0 0 0 18px;
            padding: 0;
            font-weight: 600;
            list-style-type: none;
            margin-containeron: relative;
            position:relative;
            &:before {
                position: absolute;
                top: 0;
                left: -18px;
                content: "\000B7";
            }
        }
    }
    &.readMore,
    &.generic,
    &.productSpecs,
    &.double,
    &.doubleBleed {
    }

    .floatRight {
        margin-left: 30px;
        max-width: 50%;
        height: auto !important;
        img {max-width:100%;}
    }
    .floatLeft {
        max-width:100%;
        margin-right: 30px;
        max-width: 50%;
        height: auto !important;
        img {max-width:100%;}

    }

    .full {
        width:100%;
        img {width:100%;}
    }

    input, textarea, button {
        @include basictransition(300ms);
        font-family: $font-family-paragraph;
    }
    textarea {
        overflow:auto;
    }
    img, iframe {
        max-width:100%;
    }
    img {
        height:auto !important;
    }
}
