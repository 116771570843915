/* Bloc style */
$blocSombre: '.blocSombre';
$blocClair: '.blocClair';
$blocColor: '.blocColor';
$blocPattern: '.fondPattern';

/* ###################################
 *  Styles */

#{$blocSombre} {
    color: #fff;
    background: #000;

    .gros_titre,
    h1 {
        font-weight: 900;
        line-height: 1.2em;
    }

    select, input[type="text"], input[type="password"], input[type="email"], textarea {
        background: transparent;
        color: #fff;
        border: solid 2px #fff;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        -webkit-text-fill-color: #fff !important;
    }
    .bouton {
        background-color: #fff;
        border-color: #fff;
        color: #000 !important;
        &:hover,
        &.active {
            background: transparent;
            border-color: $color-secondary;
            color: $color-secondary !important;
        }
        &:active {
        }
        &[disabled] {
        }
    }
    .bar {
        background: #fff;
    }
    h1, h2, h3,
    .gros_titre, .moyen_titre, .petit_titre {
        color: $color-secondary;
    }
    &.facts {
        background-color:$color-secondary-dark;

		dl{
			width:auto !important;
		}
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px black inset;
        -webkit-text-fill-color: #fff !important;
    }
}

#{$blocClair} {
    .gros_titre,
    h1 {
        color: $color-secondary-dark;
    }
    &.facts {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
		margin: -55px 0 -65px;

        .inner {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

		dl{
			width:auto !important;
		}

        dt {
            color:$color-secondary-dark;

            &.cercle {
                border-color:$color-secondary-dark;
            }
        }
        dd {
            color:$color-secondary-dark;
        }
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #000 !important;
    }
}

#{$blocPattern} {
    background-repeat: repeat;
    background-size: auto;
}

#{$blocPattern}.fondBas {
    background-position: center bottom;
    background-size: initial;
    background-repeat: no-repeat;
    padding-bottom: 140px;
}

.bg-bottom {
    background-position:bottom center;
}

/* ###################################
 *  Blocs de base */

.wow {
	visibility:hidden;
}

.inner {
    padding: 100px 30px;
}
.innerContainer {
    .blocClair {
        margin-top:0 !important;
        margin-bottom:0 !important;
    }
    >div.inner,
    >div .inner {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top:0 !important;
        margin-bottom:0 !important;
    }
    >div.inner:nth-child(1),
    >div:nth-child(1) .inner {
        padding-top: 100px;
        padding-bottom: 0px;
    }
    >div.inner:nth-child(2),
    >div:nth-child(2) .inner {
        padding-top: 0px;
    }
    >div.inner:last-child,
    >div:last-child .inner {
        padding-bottom: 100px;
    }
}

.single {
    text-align: left;
}

.frameBlanc .inner > div {
    padding: 30px 0;
    > div,
    > h1 {
        padding: 0 30px;
    }
}

.double {
    text-align: left;
    .col {
        width: 47%;
        float: left;
    }
    .col1 { margin-right: 3%; }
    .col2 { margin-left: 3%; }
}

.minimal {
    position: relative;
    .photoLegend { position: absolute; bottom: 15px; right: 30px; text-shadow: 0 0 15px #000000; }
}
.borderBox {
    border: solid 1px #000;
    padding: 50px;
}

.readMore {
    .readMoreDetails {
        .readMoreBtn {
            text-align: center;
            padding-bottom: 20px;
            cursor: pointer;
            .line {
                border-top: solid 3px #aaa;
                margin: 13px 0;
                width: 44%;
            }
            .left {
                float: left;
            }
            .right {
                float: right;
            }
            .more {
                width: 1px;
                height: 25px;
                width: 25px;
                position: relative;
                display: inline-block;
                .moreX {
                    border-bottom: solid 3px $color-primary;
                    width: 25px;
                    left: 0;
                    top: 12px;
                    @include basictransition(500ms);
                    opacity: 1;
                    position: absolute;
                }
                .moreY {
                    height: 25px;
                    left: 12px;
                    top: 0px;
                    border-right: solid 3px $color-primary;
                    @include basictransition(500ms);
                    position: absolute;
                }
            }
            &.active {
                .moreX { opacity: 0; }
                .moreY {
                    -moz-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
            &:active {
                opacity: 0.8;
            }
        }
        .readMoreText {
            display: none;
        }
    }
}

.negativeTop {
    margin-top:-70px;
}
.negativeBottom {
    margin-bottom:-70px;
}

/* ###################################
 *  Blocs particuliers */

.contactForm {
    position: relative;
    //display: table;
    width: 100%;

	h2{ padding-top:0; }

    p {
        margin: 20px 0 8px;
    }
    table {
        width: 100%;
        .row1 {
            vertical-align: top;
            .col {
                vertical-align:top;
                &.col1 {
                    width: 36%;
                    text-align: right;
                    > div {
                        margin: 0 0 0 auto;
                        width: 100%;
                        text-align: left;
                        max-width: 525px;
                        &.wide-margin {
                            max-width: 470px;
                            padding-right: 55px;
                        }
                    }
                }
                &.col2 {
                    width: 61%;
                    height: 60%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    padding: 0px 0px 0px 30px;
                }
            }
            .col-spacer {
                width: 3%;
            }
        }
    }

    dl {
        vertical-align: middle;
        margin-top: 30px;
        line-height: 1.4em;
        dt { font-weight: bold; width: 25%; max-width: 250px; display: inline-block; text-transform: uppercase; vertical-align: top; }
        dd { display: inline-block; width: 70%; margin: 0; vertical-align: top; font-weight: 600; }
        dt { width: 33px; float: left; clear: both; }
        dd { width: 80%; margin-bottom: 15px; float: left; }
    }
    h2 { clear: both; margin-top: 0; font-size: 1.47em; }


    select:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, textarea:focus {
        border: solid 1px #fff !important;
        outline: none;
    }

    select, input[type="text"], input[type="password"], input[type="email"], textarea {
        border: none !important;
        border-bottom: solid 1px $color-secondary !important;
        margin-bottom: 0px;
        padding: 10px 0;
        vertical-align: middle;
        font-size: 13px;
    }
    .col-spacer {
        vertical-align: top;
        span {
            display: block;
            height: 400px;
            border-left: solid 1px $color-secondary !important;
        }
    }
    ul {
        margin-bottom: 35px;
        li {
            margin-left: 0;
            &:before {
                display:none;
            }
        }
    }

    .fields {
        label {
            span {
                font-family: $font-family-paragraph;
                display: block;
                color: $color-primary;
                text-transform: uppercase;
                font-size: 1.2em;
                margin: 20px 0 5px;
            }
        }
        input, textarea {
            width: 80%;
            display: block;
            font-size:1em;
            &:focus {
                border: none !important;
                border-bottom: solid 1px #fff !important;
            }
        }
        textarea {
            height:120px;
        }
    }
    a {
        color: #fff !important;
    }

    .fieldButtons {
        width: 80%;
        position: relative;
        .zoneCv {
            float: left;
            position: relative;

            input { cursor: pointer; }

            .fileUpload {
                @include basictransition(0ms);
                position: relative;
                overflow: hidden;
                border: none;
                padding-left: 20px;
                padding-right: 20px;
                margin: 2px;
                &:hover,
                &.active {
                    border: solid 2px $color-primary;
                    margin: 0;
                }
            }
            .fileUpload input.upload {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                margin: 0;
                padding: 0;
                cursor: pointer;
                opacity: 0;
                filter: alpha(opacity=0);
            }

            #contactPersonneCvLabel {
                margin-left: 5px;
                margin-right: 20px;
                font-style: italic;
            }
        }
        .zoneSubmit {
            position: relative;
        }
    }
    .securityContainer {
        display: inline-block;
        padding: 10px 0 40px;
    }
    #contactStep2 {
    }
    #contactStep3,
    #contactMessageError {
        font-size: 1.5em;
    }

    table {
        .row1 {
            .col {
                &.col2 {
                    height: 40%;
                }
            }
        }
    }
    &.no-sidebar {
        .photo {
            width:270px;
            float:left;
            display:inline-block;
        }
        table .row1 .col {
            &.col-spacer {
                width:2%;
            }
            &.col1 {
                width:39%;
                > div {
                    max-width: inherit;
                }
                .address-bloc {
                    margin-bottom: 45px;
                    clear:both;
                    &:last-child {
                        margin-bottom:0;
                    }
                    .info {
                        display: inline-block;
                        width:40%;
                    }
                }
                dl, h2 {
                    width:100%;
                }
            }
            &.col2 {
                width:37%;
                .contactForm .fields input, .contactForm .fields textarea {
                    width: 95%;
                }
            }
        }
    }
}

.tiles-item {
    width: 33%;
    display: inline-block;
    zoom: 1;
    vertical-align: top;
    margin-bottom: 10px;
    line-height: 1.8em;
    .tiles-picture {
        width: 313px;
        height: 302px;
        line-height: 302px;
        background-repeat: no-repeat;
        background-position: center bottom;
        margin-bottom: 10px;
        background-size: cover;
        background-color: #000000;
        background-image: url('~src/images/placeholder-worker.png');
        text-align: center;
        div {
            display: inline-block;
            vertical-align: middle;
            line-height: normal; /* on r�tablit le line-height */
        }
        .bouton {
            padding: 15px 30px;
        }
    }
    .moyen_titre {
        margin: 30px 0 5px;
    }
    .equipe-poste {
        font-style: italic;
        margin-bottom: 15px;
    }
    .eq-symbol {
        color: $color-primary;
        font-weight: bold;
        display: inline-block;
        width: 26px;
        font-size: 1.2em;
    }
    a {
        font-weight: bold;
        color: #fff;
    }
}

/* Google Map */
#map, .mapGoogle {
    #google-map {
        width: 100%;
        min-height: 740px;
        position: relative;

        .mapLabel {
            /*background: url('~src/images/maplabel.png') no-repeat left center;*/
            color: #fff;
            font-size: 1.6em;
            text-transform: uppercase;
            padding: 8px 15px 8px 25px;
            width: auto !important;
            border-right: solid 6px #fdd500;
            white-space: nowrap;
        }

        .mapBubble {
            height: 1px;
            overflow: visible;
            position: relative;
        }
        .mapBubble .bubbleText {
            background: $color-primary;
            color: #fff;
            padding: 15px 12px;
            width: 200px;
            position: absolute;
            bottom: 110px;
            left: -112px;
        }
        .mapBubble .titre {
            font-size: 1.4em !important;
        }
        .mapBubble .bubbleText:after {
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 20px 10px 0 10px;
            border-color: $color-primary transparent transparent transparent;
            position: absolute;
            content: '';
            margin-left: -10px;
            bottom: -20px;
            left: 50%;
        }

        .gm-style-cc,
        .gm-style-cc span,
        .gm-style-cc div,
        .gm-style-cc a {
            background: transparent !important;
            color: #d9d9d9 !important;
            padding: 2px; }
        .gm-style-cc a:hover {
            color: #BD8D16 !important; }
    }
}

button.btn-map {
    margin: 20px 20px 0 0;
    color: #000;
    width: 30px;
    height: 30px;
	min-height:0;
	padding:0;
	background-color:#ffffff;
	border-color:#1C3509;
	color:#1C3509 !important;
    line-height: 30px;
    font-size: 15px !important;
	font-family: Arial, sans-serif;
	font-weight: bold;
    cursor: pointer;
    position: relative;
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 0;
    z-index: 99999;
}

button.btn-map-zoom-in {
    margin: 20px 4px 0 0;
    right: 50px;
}

button.btn-map:hover {
    opacity: 0.5;
}

button.btn-map-close {
    background: url('~src/images/cross-green.png') no-repeat center center !important;
    width: 32px;
    height: 32px;
    border: none;
    background-size: 100% !important;
    top: 20px;
    bottom: inherit;
    margin: 0px 20px 20px 0;
}

#siteContent {
	position:relative;
	z-index:1000;
}

.blocArea {
    display: table;
    width: 100%;
    height:100%;
    overflow:hidden;
    .blocLeft {
        display: table-cell;
        position: relative;
        z-index:10;
    }
    .blocRight {
        display: table-cell;
        position: relative;
        vertical-align: middle;
        width: 270px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index:10;
        height:100%;
        &.menuCieContainer {
            vertical-align:top;
            .menuCie {
                position:absolute;
                top:0;
                right:0;
            }
        }
        .container, .cycle, .slide, .cms-plugin {
            height:100%;
            display:block;
        }
        .slide {
            width:100%;
            height:100%;
            position:absolute;
            background-position: center center;
            background-size:cover;
        }
        .facts {
            background:transparent;
            .inner {
                padding:0;
            }
        }
    }
    .blocPied {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 220px;
        display: table-cell;
        background-repeat:repeat-x;
        background-position:bottom center;
    }
}
_:-ms-fullscreen, :root .blocArea .blocRight {
    height:0px !important;
}

.columns3 {
    .col {
        vertical-align: top;
        width: 33%;
        display: inline-block;
    }
    h2 {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    .bordered {
        border-right: solid 1px #000;
    }
    .col1 {
        margin-right: 3%;
        width: 30%;
    }
    .col2 {
        margin-right: 3%;
        width: 30%;
    }
    .col3 {
        width:31%;
    }
}

.columns2 {
    .col {
        vertical-align: top;
        width: 49%;
        display: inline-block;
    }
}

.facts {
    text-align: center;
    padding: 50px 60px;
    dt {
        font-size: 2.2em;
        font-weight: bold;
        color: $color-secondary;
        margin: 20px 0 0;
        &.cercle {
            width: 70px;
            height: 70px;
            line-height: 78px;
            border-radius: 70px;
            display: inline-block;
            border: solid 1px $color-secondary;
        }

		span{
			width: 100%;
			height: 100%;
			display: block;

			&.icon-employes{
				background:url(~src/images/icon-employes.png) center center no-repeat;
			}
		}
    }
    dd {
        color: #fff;
        margin: 15px 0;
        font-size: 0.85em;
        font-weight: 600;
        line-height: 1.3em;
    }
    &.bg-dark {
        dt {
            span{
                &.icon-employes{
                    background:url(~src/images/icon-employes-w.png) center center no-repeat;
                }
            }
        }
    }
}

.container.blocFaitesCarriere {
    .col {
        min-height: 312px;
    }
    .col1 {
        width: 33%;
        margin-right: 5%;
        border-right: solid 1px $color-primary;
    }
    .col2 {
        width: 28%;
        margin-right: 5%;
        border-right: solid 1px $color-primary;
    }
    .col3 {
        width: 27%;
    }
    .carriere-sous-titre {
        width:40%;
        float:left;
    }
    .carriere-button-postule {
        width:40%;
        float:right;
        margin:60px 0 80px;
		color:#ffffff;
    }
}

.blocAbout {
    .location {
        text-transform: uppercase;
        position: relative;
        font-weight: bold;
        display: block;
        margin-bottom: 0px !important;
        padding-left: 36px;
        color:#1c3609 !important;

        &:after {
            width: 21px;
            height: 21px;
            display: inline-block;
            content: " ";
            position: absolute;
            left: 0px;
            top: 7px;
            background: url('~src/images/marker.png') no-repeat center center;
            background-size: 14px 21px;
        }

            .small{
            font-size: 12px;
            margin-left: 15px;
            font-weight: normal;
            }
    }


	.territoire{
        a.mapLink{
            color:#1c3609;
            font-weight: bold;
            text-transform: none;
            @include basictransition(300ms);

            &:hover{ color:#960027; }
        }
        .moyen_titre{
            font-size:20px;
            text-transform: none;
        }

        .petit_titre{
            font-size:14.5px;
            font-weight: bold;
            text-transform: uppercase;
        }

		a { text-transform: uppercase; font-weight: bold; }
		li { font-weight: normal; line-height: 1.2em; margin-bottom: 15px !important; }

		.camps { margin-bottom: 5px !important; }
        .mapPoints {
            display:none;
        }
    }
}

.contactMap {
    .btn-map-close {
        display:none;
    }
}

.blocLeft {
    .facts {
        position:relative;
        dl {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: table-cell;
			width:10%;
            vertical-align: middle;
            dt {
                vertical-align:bottom;
                &.cercle {
                    margin-top:10px;
                }

				&.long8{ font-size:2em; }
				&.long16{ font-size:1.8em; }
				&.long24{ font-size:1.5em;}
				&.long32{ font-size:1.2em;}
            }
            dd {

            }
        }
    }
}

.slider-carriere,
.blocArea-carriere .blocRight {
    background-image: url('~src/images/photos/side2.jpg');
}
.slider-cote,
.blocArea-content .blocRight {
    background-image: url('~src/images/photos/side3.jpg');
}
.slider-contact,
.blocArea-contact .blocRight {
    background-image: url('~src/images/photos/side1.jpg');
}
.slider-articles,
.blocArea-articles .blocRight {
    background-image: url('~src/images/photos/side-articles.jpg');
}

/* Page Équipe */
.blocEquipe{
	table{
		border-collapse: collapse;
		width:100%;
		tr{ min-height:20px; }
		td{
			min-height:20px;

			&.spacer{width:50%;}
			&.spaceBetween{
				img{
					width:30px;
					height:30px;
				}
			}

			a{ color:#000000; }

            &.membreEquipeCell {
				vertical-align:top;

				a{ text-decoration:none; }
			}

			.membreEquipe{
				width:277px;
				text-align:center;
				padding:0 0 30px;
				box-sizing:border-box;

				.photo{
					margin-bottom:42px;
					overflow:hidden;
					img{
						 @include basictransition(500ms);
					}
				}
				.nom{
					font-size:22px;
					font-weight: bold;
					color:#960027;
					margin-bottom:10px;
					padding:0 30px;
				}
				.titre{
					font-size:14.5px;
					font-weight: bold;
					text-transform: uppercase;
					padding:0 30px;
					line-height:1.5em;
				}
				.sousTitre{
					font-size:14.5px;
					font-weight: bold;
					padding:0 30px;
					margin-top:28px;
					line-height:1.5em;
				}
				.courriel{
					background-color:#d9b67e;
					color:#ffffff;
					font-size:18px;
					display:inline-block;
					line-height:42px;
					width:42px;
					border-radius:100%;
					margin-top:20px;
					 @include basictransition(500ms);

					a{
						color:#ffffff;
					}
				}

				&:hover{
					.photo{
						img{
							-webkit-transform: scale(1.1);
							-moz-transform: scale(1.1);
							-o-transform: scale(1.1);
							-ms-transform: scale(1.1);
							transform: scale(1.1);
						}
					}
					.courriel{
						background-color:#960027;
					}
				}
			}

            .membreEquipe{
                height:264px;
                width:430px;
                overflow:hidden;
                padding:0px;
                white-space: nowrap;
                text-align:left;
                .membre-info {
                    display:inline-block;
                    vertical-align:top;
                    text-align:left;
                    padding:30px 0 0 20px;
                    width:165px;
                    white-space: normal;
                }
                .photo{
                    vertical-align:top;
                    display:inline-block;
                    margin-bottom:0px;
                    width:216px;
                    img {
                        width:216px;
                    }
                }
                .nom{
                    font-size:22px;
                    font-weight: bold;
                    color:#960027;
                    margin-bottom:10px;
                    padding:0 0px;
                }
                .titre{
                    font-size:14.5px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding:0 0px;
                    line-height:1.5em;
                }
                .sousTitre{
                    font-size:14.5px;
                    font-weight: bold;
                    padding:0 0px;
                    margin-top:20px;
                    line-height:1.5em;
                }
                .courriel{
                    text-align:center;
                }

                &:hover{
                    .photo{
                        img{
                            -webkit-transform: scale(1.1);
                            -moz-transform: scale(1.1);
                            -o-transform: scale(1.1);
                            -ms-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                    .courriel{
                        background-color:#960027;
                    }
                }
            }
		}
	}
}
.bloc-commandite .blocSombre {
    background-color:#1c3609;
    .zone-bouton {
        margin-top:20px;
        height:50px;
        line-height:50px;
        vertical-align:middle;
        padding-left:53px;
        background:url('~src/images/clipboard.png') no-repeat left center;
        .bouton {
            padding-left:30px;
            padding-right:30px;
        }
        .lien {
            color:#fff;
            text-decoration:underline;
            padding:0 0 0 30px;
        }
    }
}
.bloc-carriere {
    background-image:url('~src/images/photos/motif-bois.png');
    background-size:cover;
    background-position:center;
}
.bloc-article,
.bloc-articles,
.bloc-articles-list{
    &.blocSombre {
        background-image: url('~src/images/photos/motif-articles.jpg');
        background-size: cover;
        background-position: center;

        h1, h2, h3, .gros_titre, .moyen_titre, .petit_titre {
            color: #fff;
        }
    }

    &.blocClair {
        .bouton {
            color:#fff !important;
            background:$color-secondary;
            border-color:$color-secondary;
        }
        article {
            border-color:$color-secondary;
        }
    }
    .bouton {
        color:$color-secondary !important;
        &:hover,
        &.active{
            background-color:$color-primary !important;
            border-color:$color-primary !important;
        }

		&.contactIcon{ margin-top:10px; }
    }
    .col {
        width:48%;
        &:nth-child(even) {
            margin-left:3%;
        }
    }
	&.bloc-articles{
		article{ padding-bottom:60px; }
	}

    article {
        border:solid 1px #fff;
        padding:15px 32px 22px;
        margin:20px 0 28px;
        color:#000;
        position:relative;

        h1,  h2,  h3,  .gros_titre,  .moyen_titre,  .petit_titre {
            color:#000 !important;
        }
        .bouton {
            background:#000 !important;
            color:#fff !important;
            border-color:#000 !important;
            &:hover,
            &.active {
                color: #fff !important;
            }
        }
        .article-bar {
            position:absolute;
            bottom:0;
            left:0;
            margin:0 32px 22px;
        }
    }
    .articles-categories {
        width: calc(100% + 2px);
        display:table;
        border-spacing: 1px;
        margin:20px -1px 40px;
        .bouton {
            display:table-cell;
            width:10%;
            text-transform: uppercase;
            font-size:1.3em !important;
            line-height: 1.6em;
        }
    }
    .inner {
        .article-browser {
            width: 100% !important;
            h2 {
                text-align: left;
            }
            > div {
                width:calc(100% + 1px) !important;
                text-align:left !important;
            }
            .bouton {
                width:50%;
                border-right:solid #fff 1px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
        }
    }
}
.bloc-societe {
    z-index:10000;
    .blocLeft {
        z-index:1000;
    }
}
.bloc-articles-list,
.bloc-article{
    h1 {
		color:$color-secondary;
		display:inline-block;
	}
}
.bloc-article {
	padding-bottom:50px;

    .socials {
        float:none;
        li:nth-child(1) {margin-left:0;}
    }
}
.bloc-page {
    clear:both;
}
.bloc-actionnaires {
    text-align:center;
    .inner {
        padding-top:40px;
        padding-bottom:40px;
    }
    .petit_titre {
        display:block;
        padding-bottom:10px;
    }
    .actionnaire-image {
        display:inline-block;
        width:215px;
        height:265px;
        background:url('~src/images/placeholder-user.png') no-repeat center;
        background-size:cover;
    }
}
.bloc-produits {
    text-align:center;
    .inner {
        padding-top:40px;
        padding-bottom:40px;
    }
    .petit_titre {
        display:block;
        padding-bottom:10px;
    }
    .produit-image {
        display:inline-block;
        width:250px;
        height:188px;
        background:url('~src/images/placeholder-produit.png') no-repeat center;
        background-size:cover;
    }
}
.bloc-equipements {
    .inner {
        padding-top:40px;
        padding-bottom:40px;
    }
    .petit_titre {
        display:block;
        padding-bottom:10px;
    }
}
.socials {
    float:right;
    margin-top: 6px !important;
    margin-bottom: 0px !important;
    li {
        cursor:pointer;
        list-style: none;
        width:24px;
        height:24px;
        display:inline-block;
        background-position:center;
        background-repeat:no-repeat;
        &:before {
            display:none;
        }
    }
    .icon-twitter {background-image:url('~src/images/icon-twitter.png'); &:hover {background-image:url('~src/images/icon-twitter-h.png');}}
    .icon-fb {background-image:url('~src/images/icon-fb.png'); &:hover {background-image:url('~src/images/icon-fb-h.png');}}
    .icon-linkedin {background-image:url('~src/images/icon-linkedin.png'); &:hover {background-image:url('~src/images/icon-linkedin-h.png');}}
    .icon-mail { display:none; background-image:url('~src/images/icon-mail.png'); &:hover {background-image:url('~src/images/icon-mail-h.png');}}
    .icon-print { background-image:url('~src/images/icon-print.png'); &:hover {background-image:url('~src/images/icon-print-h.png'); color:$color-primary;}
        width: auto;
        background-position: left center;
        line-height: 25px;
        padding: 0 0 0 30px;
    }
}
.filiales-list {
    text-align:center;
	display:flex;
	justify-content:space-between;
	flex-flow: row wrap;

	li {
		flex-grow:1;
		display:inline-block;
		height: 70px;
		width: 190px;
		line-height: 164px;
		text-align:center;
		margin:40px auto;
		padding:0 15px;
		box-sizing:border-box;
		position:relative;

		img {
			max-width: 90%;
			max-height: 90%;
			vertical-align: middle;
			-webkit-transition: 0.3s ease;
			transition: 0.3s ease;
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
            filter: gray; /* IE6-9 */
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}

		&:hover{
			img{
				filter: none;
				-webkit-filter: none;
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		&:nth-child(5n+1):not(:first-child){
			&:before{
				content:' ';
				position:absolute;
				top:10px;
				left:0;
				width:950px;
				height:1px;
				background-color:#d9b67e;
			}
		}

	}


}
.filiales-btns {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: -20px;
    margin-bottom: -20px;
    ul {
        text-align:center;
        li {
            list-style:none;
            padding: 0;
            margin: 0;
            width: 49%;
            display: inline-block;
            text-align: center;
            &:nth-child(odd) {
                margin-right:2%;
            }
            &:before {
                display:none;
            }
            .bouton {
                margin-top:20px !important;
            }
        }
    }
}


.template-filiale {
    .slider-cote,
    .blocArea-content .blocRight {
        background: #fff url('~src/images/photos/default.png') repeat-y bottom center;
    }
}


.template-filiale,
.template-equipe,
.template-page{
    #enteteSlideshow{
        width:100%;
        height:53vh;
        padding:0;
        margin:0 auto 0;
        position:relative;
        overflow:hidden;
        background-size:100% auto !important;
        background:#fff;
        background-position:center center;
        z-index: 20;

        img {
            width:100%;
        }

        #bandeauImages {
            z-index:100;
            position:absolute;
            top:0;
            width:100%;
        }
        h1, .gros_titre {
            text-shadow: 0 0 25px #000000;
        }

        .slide{
            padding-top:30px;
            width:100%;
            height:53vh;
            background-repeat:no-repeat;
            background-size:cover;
			background-position:50% 50%;
        }
        > .inner,
        .slide {
            line-height:1.5em;
            color:#fff;
            h1,
            .inner {}
        }
        .gros_titre {
            font-style:normal;
            font-family:$font-family-paragraph;
        }
    }


	&.template-page,
	&.template-equipe{
		#enteteSlideshow{
			height:41vh;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            .slide{
                height:40vh;
            }
		}
		.blocAbout {
			height:42vh;
			line-height:42vh;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
		}
	}

	&.template-page{
		.bloc-carriere{ border-top:1px solid #d9b67e; }
	}

    .blocAbout {
        height:30vh;
        line-height:30vh;
        text-align:center;
        .inner {
            vertical-align:middle;
            display:inline-block;
            line-height:normal; /* on rétablit le line-height */
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            text-align:left;

			h1{ margin-bottom: 10px; }
        }
    }
    .facts {
        padding:0;
		margin-right:-1px;

        &.blocSombre, &.bg-dark {
            .inner {
                padding-top: 25px !important;
                padding-bottom: 25px !important;
            }
        }

		dl{
			padding: 0px 20px;
		}
    }
}
.template-page{
    .blocAbout {
        height:auto;
        line-height:normal;
        text-align:center;
    }
    .blocArea {
        .blocRight {
            .cycle {
                .cms-plugin,
                .slide {
                width:100%;
                height:100%;
                position:absolute;
                }
            }
        }
    }
    .blocAbout {
        color:#fff;
        background-color:#1c3609;
    }
    .bloc-page {
        .bouton {
            background-color: $color-secondary-dark;
            border-color:$color-secondary-dark;
            padding-left:30px;
            padding-right:30px;
            margin:10px 0;
            &:hover,
            &.active{
                background-color:#000;
                border-color:#000;
            }
        }
        .inner {
            padding-top:50px;
            padding-bottom:50px;
        }
    }
    p {
        margin-bottom:20px;
    }
    .menuCie {
        background: #fff url('~src/images/photos/default.png') repeat-y bottom center;
    }
}
.template-societe {
    .blocLeft {
        .blocAbout {
            .inner {
                padding-bottom: 220px;
            }

            .columns2 ul li {
                margin: 0px 0 10px;
                padding-left: 36px;
                position: relative;
                &:before {
                    display: none;
                    content: "";
                }
            }

        }
    }
    .slider-cote {
        vertical-align:top;
        .facts {
            padding-top:90px;
        }
    }
}

.template-equipe,
.template-page,
.groupe-remabec{
	.blocArea{
		.blocRight{
			&.slider-contact{
				background-image: url("~src/images/photos/fond-contact-270.jpg");
				background-position: left bottom;
				background-repeat: no-repeat;

				.slide{
					background-position: left top;
					background-repeat: no-repeat;
				}
			}
		}
	}
}

 #map-fixed {
     display:none;
	 position: fixed;
	 z-index:99998;
	 bottom: 0;
	 left: 0;
	 width: 100%;
	 height:100vh;
	 width:calc(100vw - 285px);

	#google-map{
		height:100%;
	}
}

.template-equipe {
    .menuCie {background:none;}

    .slider-cote {
        background-image: url("~src/images/photos/team.jpg") !important;
    }
}

.template-article,
.template-articles-list{
    .menuCie {
        background: #fff url('~src/images/ligne-beige.png') repeat-y left;
    }
    .article-browser {
        text-align:center;
        margin:0 auto 30px;
        width:70%;
        h2 {
            font-size: 1.8em;
            margin-top: 15px;
        }
        .bouton, select {
            width:100%;
            padding-left:10px;
            padding-right:10px;
        }
        .bouton {
            margin-bottom:1px;
            background:$color-secondary;
            border-color:$color-secondary;
            &:hover,
            &.active{
                background:$color-primary;
                border-color:$color-primary;
            }
        }
        select {
            border-color:$color-secondary !important;
            background-image:url('~src/images/select-light.png');
            font-weight: bold;
            color: #000;
            font-size: 0.9em;
            option {

            }
        }
    }
}

/* Page Arbec */
.locationArbec{
	padding-left:28px;
	position:relative;
    background: url('~src/images/marker-rouge.png') no-repeat 0px center;
    background-size: 14px 21px;
}
table.blocArbec{
	border-collapse: collapse;
	width:100%;
	tr{ min-height:20px; }
	td{
		min-height:20px;

		.locationArbec{
			 margin:30px 0 20px -28px;
		}

		&:first-child{ border-left-width: 0; }
		&:last-child{ border-right-width: 0; }

		&.spacer{}
		&.spaceBetween{
			img{
				width:30px;
				height:30px;
			}
		}

		&.title{ border:0; }
		&.noBorder{ border:0; }

		&.usineArbecCell {vertical-align:top;}

		.usineArbec{
			padding:30px;
			text-align:center;
			box-sizing:border-box;
			position:relative;

			.photo{
				overflow:hidden;
				img{
					@include basictransition(500ms);
				}
			}
		}
	}
}

.arbec .bloc-societe h1 {
    font-family: 'helvetica_blackregular';
    color: #1c252c;
}

.tipsy,
.tipso_bubble,
.usine-info{
	display:none;
	width:315px;
	box-sizing:border-box;
	padding:25px;
	background-color:#c41a2b;
	text-align:left;
	border-radius:0;

	h2{
		color:#ffffff;
		font-size:20px;
		margin: 0 0 20px;
		padding: 0;
		line-height:20px;
	}
	h3{
		color:#ffffff;
		font-size:14.5px;
		font-weight: bold;
		margin: 0 0 8px;
		padding: 0;
	}
	.texte{
		font-size:14.5px;
		color:#ffffff;
		margin: 0 0 18px;

		&:last-child{ margin-bottom:0; }
	}
}

.genericFormBloc,
.genericPopup,
#formContactPopup,
#formCommandite {
    min-height:inherit;
	width:80%;
    max-width:590px;
	margin:auto;
	padding: 75px;
	background-color:#FFFFFF;
	border:1px solid;
	display: none;
	#messageRetour.success{ color:#42b569; }
	#messageRetour.error{}

    h1 {
        font-size:1.7em;
        margin: 0;
        padding: 0;
    }
    h2 {
        margin: 5px 0;
    }
    .moyen_titre {
        line-height:1.1em;
    }

    .border {
        border:solid 1px $color-secondary;
        padding:45px;
    }
    ul {
        margin:0;
    }

	.g-recaptcha{
		margin:20px auto;

		> div{
			> div{
				margin:0 auto;
			}
		}
	}
	.inner {
		padding:60px 0;
	}

    input,
    textarea {
        width:100%;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-color: #d9b67e !important;
        color: #000;
    }

    .bouton {
        background-color:#000;
        border-color:#000;
        color:#fff !important;
        &:hover,
        &.active {
            background-color:$color-primary;
            border-color:$color-primary;
            color:#fff !important;
        }
    }

	.fields {
		label {
			span {
				display:block;
				text-transform:uppercase;
				font-size:1.2em;
				margin:20px 0 5px;

			}
		}
	}
	.fieldButtons {
		height:30px;
		position:relative;
		.zoneCv {
			float:right;
			position:relative;

			input {cursor:pointer;}

			.fileUpload {
				@include basictransition(0ms);
			    position: relative;
			    overflow: hidden;
			    /*background:url('~src/images/icon-file.png') no-repeat center center;*/
			    padding-left:20px;
			    padding-right:20px;
			}
			.fileUpload input.upload {
			    position: absolute;
			    width:100%;
			    height:100%;
			    top: 0;
			    right: 0;
			    margin: 0;
			    padding: 0;
			    cursor: pointer;
			    opacity: 0;
			    filter: alpha(opacity=0);
			}

			#contactPersonneCvLabel {
				margin-left:5px;
				margin-right:20px;
				font-style:italic;
			}
		}
		.zoneSubmit {
			position:relative;
			float:left ;
		}
	}
	.securityContainer{
		display: inline-block;
 	 	padding: 10px 0 40px;
	}
	#contactStep3,
	#contactMessageError {
		font-size:1.5em;
	}
}
.genericFormBloc,
.genericPopup {
    display:block;
    position:relative;
    line-height:1.3em;

    .row {
        margin:20px 0 0;
        label {
            font-weight:bold;
            width:200px;
            display:inline-block;
        }
        .info {
            width:calc(100% - 210px);
            display:inline-block;
        }
    }
    .rowBloc {
        margin:20px 0 40px;
        .row {margin:0;}
    }
}
.genericFormBloc {
    display:block;
    margin:0;
    padding:0;
    border:none;
    max-width:inherit;
    width:inherit;
    p {
        margin:0;
    }
}
#formCommandite {
    padding:45px 55px;
    .fields {
        li {
            label,
            .labelGroup {
                margin: 30px 0;
                span {
                    color:#000;
                    font-size: 0.9em;
                    font-weight: normal;
                    text-transform: none;
                    margin-bottom: 0;
                }
                .checkbox-group,
                .radio-group {
                    margin-bottom:10px;
                    .jcf-checkbox,
                    .jcf-radio,
                    .label {
                        display:inline-block !important;
                        margin-top:0;
                    }
                    .label {
                        margin:0 30px 0 10px;
                    }
                    .jcf-checkbox,
                    .jcf-radio{
                        border-color:#d9b67e !important;
                    }
                    .jcf-checkbox.jcf-checked,
                    .jcf-radio.jcf-checked {
                        background:#d9b67e;
                    }
                    input[type="text"] {
                        display:inline-block;
                    }
                }
                input, textarea {margin-top:0; padding-top:0; font-weight:bold;}
            }
            &.mandatory {
                span:after {
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    content: '*';
                    margin-left: -10px;
                    top: 0px;
                    left: 0;
                }
            }
        }
    }
}
.step-3, .contactStepLoading {
    padding:30px 0;
}


.industrie-john-lewis-ltee {
    .menuCie {
        background-image:url('~src/images/photos/default-lewis.png');
    }
    &.template-page {
        .blocAbout {
            background: #f06b1c;
        }
    }
}

.msg-notice.bouton {
	height: auto !important;
	text-align: left !important;
	white-space: inherit !important;
	padding: 2em 2em;
}
